import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Badge, Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Divider, Fab, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Toolbar, Typography } from "@material-ui/core";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useLocation, useParams } from "react-router-dom";
import db from '../../../../utils/dexie/driverappdb';
import { FontAwesomeDuoToneStyle } from "../../../../fontawesome";
import ReactImagesCarousel, { Modal, ModalGateway } from "react-images";
import { lookUpTranslatedDescription, detectTargetArticleForAlias } from "../../../../utils/article";
import { useRef } from "react";
import { useReducer } from "react";
import { ArticleCatalogFilterContext, ArticleReducer, articleReducerInit } from "./context";
import { useContext } from "react";
import { AppContext } from '../../../../utils/contexts/appContext';

const useStyles = makeStyles(theme => ({
    toolbar: {
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: 2,
        backgroundColor: '#FFF'
    },
    filtersWrapper: {
        marginTop: '15px !important',
        marginBottom: '15px !important' 
    },
    filterOffset: {
        marginBottom: 25,
        ...theme.mixins.toolbar
    },
    articleBoxContainer: {
        paddingBottom: '80px !important'
    },
    articleRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    articleMedia: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
        backgroundColor: 'lightgray'
    },
    articleContent: {
        flexGrow: '1'
    },
    articleSelected: {
        border: `4px solid ${theme.palette.primary.main}`
    },
    brandInfo: {
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 'auto'
    },
    badge: {
        '& > .MuiBadge-badge': {
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.primary.contrastText
        }
    },
    imageFullSizeBtn: {
        position: 'relative',
        marginTop: '-100%',
        marginRight: '-78%',
        color: theme.palette.primary.contrastText,
        padding: 5
    }
}));

const Article = forwardRef(({ article, onClick = (article, isSelected) => {}, onFullSizeImage = (imgSrc) => {} }, ref) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    const [images, setImages] = useState();
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        setImages(article.images.sort((a, b) => {
            // First the main image.
            return (a.mainImage === b.mainImage) ? 0 : (a.mainImage ? -1 : 1);
        }))
    }, [article]);

    const handleOnClick = () => {
        setIsSelected((selected) => !selected);
        onClick(article, !isSelected);
    }

    const lookUpTranslatedRemark = () => {
        var translation;

        if (article != null && article.remarkTranslations != null && article.remarkTranslations.length > 0) {
            translation = article.remarkTranslations.find(t => t.language?.abbreviation.toLowerCase() === i18n.language.toLowerCase());
            
            // Fallback for a language which doesn't exists inside the labels.
            if (translation == null)
                translation = article.remarkTranslations[0];
        }

        return translation?.translatedTerm;
    }

    return (
        <Grid item container xs={12} sm={6} md={4} lg={3}>
            <Grid item xs={12} component={Card} variant="outlined" className={[classes.articleRoot, isSelected ? classes.articleSelected : ''].join(' ')}
                 onClick={handleOnClick}>
                {
                    images != null && images.length > 0 ? 
                        <Carousel showArrows showStatus={false} showThumbs={false} showIndicators={article.images.length > 1}>
                            {
                                images.map(image => 
                                    <CardMedia key={image.id} className={classes.articleMedia} title={article.name} image={`data:image/${image.extension.split('.').pop()};base64, ${image.data}`} >
                                        <IconButton className={classes.imageFullSizeBtn} onClick={(e) => { e.stopPropagation(); onFullSizeImage(`data:image/${image.extension.split('.').pop()};base64, ${image.data}`); return false; }}>
                                            <FontAwesomeIcon icon={["fas", "expand-alt"]} style={FontAwesomeDuoToneStyle} size="lg" />
                                        </IconButton>
                                    </CardMedia>
                                )
                            }
                        </Carousel> : <CardMedia className={classes.articleMedia} component='div'></CardMedia>
                }
                <CardContent className={classes.articleContent}>
                    <Typography variant="h5" component="h2">
                        {article.name}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{fontWeight: "bold"}}>
                        {lookUpTranslatedRemark()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lookUpTranslatedDescription(article, i18n.language)}
                    </Typography>
                    {
                        article.inventoryLevels != null && article.inventoryLevels.length > 0 ?
                            <Typography variant="body1" color="textSecondary">
                                {t("Quantity")}: {article.inventoryLevels[0]?.available}
                            </Typography> : <></>
                    }
                    {
                        article.unitDescription != null ?
                            <Typography variant="body1" color="textSecondary">
                                {t("Unit")}: {article.unitDescription}
                            </Typography> : <></>
                    }
                    {
                        article.supplierArticleCode != null ?
                            <Typography variant="body1" color="textSecondary">
                                {t("SupplierArticleCode")}: {article.supplierArticleCode}
                            </Typography> : <></>
                    }
                </CardContent>
                {
                    article.brandCode != null || (article.brandDescription?.length > 0) ?
                        <CardActions>
                            {
                                article.brandCode != null ?
                                    <Box className={classes.brandInfo}>
                                        <Chip label={article.brandCode} color="primary" size="small"></Chip>
                                    </Box> : <></>
                            }
                            {
                                article.brandDescription != null ?
                                    <Box className={classes.brandInfo}>
                                        <Chip label={article.brandDescription} color="primary" size="small" variant="outlined"></Chip>
                                    </Box> : <></>
                            }
                        </CardActions> :
                        <></>
                }
            </Grid>
        </Grid>
    );

});

const ArticlesCatalogFilterForm = () => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [filtersExpanded, setFiltersExpanded] = useState(false);

    const {filterState, dispatch} = useContext(ArticleCatalogFilterContext);
    const {name, description, stockArticles, brandCode, brandDescription, supplierArticleCode} = filterState.filters;

    const [{language}] = useContext(AppContext);

    useEffect(() => {
        // Make sure we reset the filterable values because they rely on translated fields.
        dispatch({ type: 'FILTER_RESET_BY_LANGUAGE', language });
    }, [language, dispatch])

    const resetFilter = () => {
        dispatch({ type: 'FILTER_RESET' });
        setFiltersExpanded(false);
    }

    const filterArticles = () => {
        dispatch({ type: 'FILTER' });
        setFiltersExpanded(false);
    };

    return (
        <Accordion className={classes.filtersWrapper} expanded={filtersExpanded} onChange={(event, expanded) => setFiltersExpanded(expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {t("Search")}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    {
                        (filterState != null && filterState.filterableNames != null && filterState.filterableNames.length > 0) ?
                            <Grid item xs={12}>
                                <TextField fullWidth label={t("Name")} value={name}  onChange={(e) => dispatch({ type: 'FILTER_CHANGE', filterField: 'name', filterValue: e.target.value })} />
                            </Grid> : <></>
                    }
                    {
                        (filterState != null && filterState.filterableDescriptions != null && filterState.filterableDescriptions.length > 0) ?
                            <Grid item xs={12}>
                                <TextField fullWidth label={t("Description")} value={description} onChange={(e) => dispatch({ type: 'FILTER_CHANGE', filterField: 'description', filterValue: e.target.value })} />             
                            </Grid> : <></>
                    }
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-stock-label">{t("StockItems")}</InputLabel>
                            <Select value={stockArticles} 
                                labelId="filter-stock-label"
                                onChange={(e) => dispatch({ type: 'FILTER_CHANGE', filterField: 'stockArticles', filterValue: e.target.value })}>
                                <MenuItem key='' value=''>{t('SelectionPlaceholder')}</MenuItem>
                                <MenuItem key='yes' value='yes'>{t('Yes')}</MenuItem>
                                <MenuItem key='no' value='no'>{t('No')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        ((filterState != null && filterState.filterableBrandCodes != null && filterState.filterableBrandCodes.length > 0) || 
                            (filterState != null && filterState.filterableBrandDescriptions != null && filterState.filterableBrandDescriptions.length > 0)) ?
                            <Grid item xs={12}>
                                <Divider />
                                <Typography gutterBottom variant="body1">{t("Brand")}</Typography>
                                <Grid container spacing={3}>
                                    {
                                        filterState.filterableBrandCodes != null && filterState.filterableBrandCodes.length > 0 ?
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="filter-brancode-label">{t("BrandCode")}</InputLabel>
                                                    <Select value={brandCode} 
                                                        labelId="filter-brancode-label"
                                                        onChange={(e) => dispatch({ type: 'FILTER_CHANGE', filterField: 'brandCode', filterValue: e.target.value })}>
                                                        <MenuItem key='' value=''>{t('SelectionPlaceholder')}</MenuItem>
                                                        {filterState.filterableBrandCodes.map(filterableBrandCode => <MenuItem key={filterableBrandCode} value={filterableBrandCode}>{filterableBrandCode}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid> : <></>
                                    }
                                    {
                                        filterState.filterableBrandDescriptions != null && filterState.filterableBrandDescriptions.length > 0 ?
                                            <Grid item xs={6}>
                                                <TextField fullWidth label={t("BrandDescription")} value={brandDescription} onChange={(e) => dispatch({ type: 'FILTER_CHANGE', filterField: 'brandDescription', filterValue: e.target.value })} />             
                                            </Grid> : <></>
                                    }
                                </Grid>
                            </Grid> : <></>
                    }
                    {
                        filterState != null && filterState.filterableSupplierArticleCodes != null && filterState.filterableSupplierArticleCodes.length > 0 ?
                            <Grid item xs={12}>
                                <Divider />
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="filter-supplierarticlecode-label">{t("SupplierArticleCode")}</InputLabel>
                                            <Select value={supplierArticleCode} 
                                                labelId="filter-supplierarticlecode-label"
                                                onChange={(e) => dispatch({ type: 'FILTER_CHANGE', filterField: 'supplierArticleCode', filterValue: e.target.value })}>
                                                <MenuItem key='' value=''>{t('SelectionPlaceholder')}</MenuItem>
                                                {filterState.filterableSupplierArticleCodes.map(filterableSupplierArticleCode => <MenuItem key={filterableSupplierArticleCode} value={filterableSupplierArticleCode}>{filterableSupplierArticleCode}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid> : <></>
                    }
                </Grid>
            </AccordionDetails>
            <AccordionActions>
                <Button size="small" onClick={resetFilter}>{t("Cancel")}</Button>
                <Button size="small" color="primary" onClick={filterArticles}>{t("Search")}</Button>
            </AccordionActions>
        </Accordion>
    );
}

const ArticleCatalogList = ({
    onChange = (article, isSelected) => {}, 
    onFullSizeImage = () => {}
}) => {
    const [t] = useTranslation();
    const {filterState} = useContext(ArticleCatalogFilterContext);
    const sortedArticles = filterState?.visibleArticles?.sort((a, b) => {
        if (a.seqNr == null)
            return 1;
        else if (b.seqNr == null)
            return -1;
        else
            return a.seqNr - b.seqNr;
    });

    return (
        <Box>
            {
                (sortedArticles == null || (!sortedArticles || sortedArticles.length === 0)) ?
                    <p>{t("NoArticlesAvailable")}</p> :
                    <>
                        <Grid container spacing={3}>
                            {
                                sortedArticles.map((article, i) => 
                                    <Article key={article.id} article={article} onClick={onChange} onFullSizeImage={onFullSizeImage} />)
                            }
                        </Grid>
                    </>
            }
        </Box>
    );
}

const ArticlesCatalogView = ({ articles }) => {
    let { dropId } = useParams();
    const location = useLocation();
    const classes = useStyles();
    const [, i18n] = useTranslation();
    
    const [, setSelectedArticleIds] = useState([]);
    const [targetOccurenceMapping, setTargetOccurenceMapping] = useState({});
    const [selectedArticleTargetIds, setSelectedArticleTargetIds] = useState([]);
    const [selectedArticlesCount, setSelectedArticlesCount] = useState(0);
    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [imagePreviewSrc, setImagePreviewSrc] = useState("");
    const saveButtonRef = useRef(null);

    const articleReducerInitialState = { 
        language: i18n.language,
        articles 
    };

    const [filterState, dispatch] = useReducer(ArticleReducer, articleReducerInitialState, articleReducerInit);

    const handleSelectionChange = async (article, isSelected) => {
        let articleId = article.id;

        // Determine the targetted article by following the aliases
        let targettedArticle = await new Promise((resolve, reject) => {
            if (article == null) {
                reject();
            } else if (article.aliasArticleId == null) {
                resolve(article);
            } else {
                detectTargetArticleForAlias(article.aliasArticleId)
                    .then(resolve);
            }
        });

        let targettedArticleId = targettedArticle?.id;

        // Update the selected articles
        setSelectedArticleIds(articleIds => {
            let result = articleIds;
            let targetOccurenceDifference = 0;

            // Add/Delete the necessary articles
            let index = articleIds.findIndex(id => id === articleId);
            if (index > -1 && !isSelected) { // Delete
                targetOccurenceDifference = -1;
                result.splice(index, 1);
            } else if (index === -1 && isSelected) { // Add
                targetOccurenceDifference = 1;
                result.splice(result.length, 0, articleId);
            }

            // We need to update the occurence mapping of the targeted elements here because it needs to be updated according to the SELECTED articles, (not the targetted) 
            setTargetOccurenceMapping(mapping => {
                let occurenceResult = (mapping[targettedArticleId] || 0) + targetOccurenceDifference;
                mapping[targettedArticleId] = occurenceResult >= 0 ? occurenceResult : 0;
                return mapping;
            });

            // Update the selected articles count using the selected articles, not the targetted ones
            setSelectedArticlesCount(result.length);
            return result;
        });

        // Also handle the change using the targetted articles
        if (targettedArticle != null) {
            setSelectedArticleTargetIds(targetIds => {
                let result = targetIds;
                
                let index = targetIds.findIndex(id => id === targettedArticleId);
                if (index > -1 && !isSelected) { // Delete
                    // Check if there are no other articles selected anymore which refer to the same targetted article
                    let stillReferencedByOthers = targettedArticleId in targetOccurenceMapping && targetOccurenceMapping[targettedArticleId] > 0;
                    if (!stillReferencedByOthers)
                        result.splice(index, 1);
                } else if (index === -1 && isSelected) { // Add
                    result.splice(result.length, 0, targettedArticleId);
                }
                
                return result;
            });
        }
    }

    const handleConfirm = async (e) => {
        if (saveButtonRef.current.disabled) {
            e.preventDefault();
            return;
        } else {
            saveButtonRef.current.disabled = true;

            return await db.transaction('rw', db.dropdetailsdata2, db.articles, async () => {
                let articles = await db.articles.bulkGet(selectedArticleTargetIds);
                let alreadyExistingArticles = (await db.dropdetailsdata2.where('[dropId+articleId]').anyOf(selectedArticleTargetIds.map(articleId => [parseInt(dropId), articleId])).toArray())
                    .filter(a => a.createdLocally);

                // Use the targetted articles for this
                let updatedDropDetailsData = selectedArticleTargetIds.map((articleId, index) => {
                    let article = articles.find(a => a.id === articleId);
                    let alreadyExistingArticleIndex = alreadyExistingArticles.findIndex(existingArticle => existingArticle.articleId === articleId);
                    let alreadyExistingArticle = alreadyExistingArticleIndex > -1 ? alreadyExistingArticles[alreadyExistingArticleIndex] : null;
                    let startingDeliveredQuantityIn = alreadyExistingArticle?.deliveredQuantityIn ?? 0;
                    let startingDeliveredQuantityOut = alreadyExistingArticle?.deliveredQuantityOut ?? 0;
                    
                    // Check how many occurrences of the article we need to take into account (to support aliases)
                    let quantityDifference = articleId in targetOccurenceMapping ? (targetOccurenceMapping[articleId] ?? 1) : 1;
                    if (article == null || (article.displayIn && article.displayOut))
                        quantityDifference = 0; // We're unable to detect which value to update, so let the user do it manually

                    return { 
                        id: alreadyExistingArticle?.id,
                        dropId: parseInt(dropId), 
                        articleId: articleId, 
                        originalQuantityIn: article?.defaultInQuantity,
                        deliveredQuantityIn: startingDeliveredQuantityIn + (article?.displayIn ? quantityDifference : 0),
                        originalQuantityOut: article?.defaultOutQuantity,
                        deliveredQuantityOut: startingDeliveredQuantityOut + (article?.displayOut ? quantityDifference : 0),
                        createdLocally: 1
                    }
                });

                return await db.dropdetailsdata2.bulkPut(updatedDropDetailsData)
                    .catch(() => saveButtonRef.current.disabled = false);
            });
        }
    }

    const handleFullSizeImage = (imgSrc) => {
        setImagePreviewSrc(imgSrc);
        setImagePreviewOpen(true);
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <ArticleCatalogFilterContext.Provider value={{filterState, dispatch}}>
                <Toolbar color="inherit" className={classes.toolbar}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} lg={8}>
                            <ArticlesCatalogFilterForm />
                        </Grid>
                    </Grid>
                </Toolbar>
                <Box className={classes.filterOffset}></Box>
                <Box className={classes.articleBoxContainer}>
                    <ArticleCatalogList onChange={handleSelectionChange} onFullSizeImage={handleFullSizeImage} />
                    <Box style={{ position: 'fixed', bottom: 15, right: 15 }}>
                        {
                            selectedArticlesCount > 0 ?
                                <NavLink replace to={{
                                        pathname: location.state.redirectionUrl,
                                        state: {
                                            selectedTab: location.state.redirectionTab
                                        }}}>
                                    <Badge ref={saveButtonRef} badgeContent={selectedArticlesCount} overlap="circle" className={classes.badge}>
                                        <Fab color="primary" style={{ marginRight: 5 }} onClick={handleConfirm}>
                                            <FontAwesomeIcon icon={["fas", "check"]} />
                                        </Fab>
                                    </Badge>
                                </NavLink> : <></>
                        }
                        <NavLink replace to={{
                                pathname: location.state.redirectionUrl,
                                state: {
                                    selectedTab: location.state.redirectionTab
                                }}}>
                            <Fab>
                                <FontAwesomeIcon icon={["fal", "times"]} />
                            </Fab>
                        </NavLink>
                    </Box>
                </Box>
            </ArticleCatalogFilterContext.Provider>
            {
                <ModalGateway>
                    {
                        imagePreviewOpen ?
                            <Modal 
                                allowFullscreen={true} 
                                closeOnBackdropClick={true} 
                                onClose={() => setImagePreviewOpen(false)}>
                                <ReactImagesCarousel views={[{src: imagePreviewSrc}]} />
                            </Modal> : <></>
                    }
                </ModalGateway>
            }
        </motion.div>
    );

}

export default ArticlesCatalogView;