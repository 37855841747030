import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import FeedbackView from '../feedback/FeedbackView';
import { lookUpTranslatedFeedbackLabel, lookUpTranslatedFeedbackOption } from './utils';

const FeedbackDropdown = forwardRef(({ uid, label, options, feedback, onRequiredChange }, ref) => {

    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [underlyingFeedbackItems, setUnderlyingFeedbackItems] = useState([]);

    const underlyingFeedbackRef = useRef();

    useEffect(() => {
        if (typeof(feedback) !== "undefined") {
            var selectedOption = options.find(o => feedback.find(f => f.labelId === label.feedbackLabelId && f.optionId === o.feedbackOptionId) || feedback.flatMap(f => f.parents[label.level]).indexOf(o.feedbackOptionId) > -1);
            if (selectedOption != null) {
                setValue(selectedOption.feedbackOptionId);
                setUnderlyingFeedbackItems(selectedOption.underlyingFeedbackLabelsAndOptions);
            }
        }

        return () => {
            setUnderlyingFeedbackItems([]);
        };
    }, [label, options, feedback]);

    useEffect(() => {
        if (typeof(label) !== "undefined" && label !== null)
            onRequiredChange(label.required);
    }, [label, onRequiredChange]);

    const onChange = (e) => {
        setValue(e.target.value);
        var option = options.find(o => o.feedbackOptionId === e.target.value);
        var underlyingItems = (typeof (option) !== "undefined" && option !== null) && option.underlyingFeedbackLabelsAndOptions !== null ? 
            option.underlyingFeedbackLabelsAndOptions.filter(item => item.feedbackOptions != null && item.feedbackOptions.length > 0) : null;
        setUnderlyingFeedbackItems(underlyingItems);
    };

    useImperativeHandle(ref, () => ({
        
        validate: (silenceMode) => {
            let validationResult = !label.required || (typeof (value) !== "undefined" && value !== null && value !== '');

            let underlyingFeedbackValid = (underlyingFeedbackRef != null && underlyingFeedbackRef.current != null) ? 
                underlyingFeedbackRef.current.validate(silenceMode) : true;

            setIsValid(validationResult);
            return validationResult && underlyingFeedbackValid;
        },
        getValue: (parents) => {
            var underlyingFeedbackItemsCount = (typeof(underlyingFeedbackItems) !== "undefined" && underlyingFeedbackItems != null) ? 
                underlyingFeedbackItems.length : 0;

            var updatedParents = (parents || []).slice(0);
            if (underlyingFeedbackItemsCount > 0) 
                updatedParents.push(value);

            if (underlyingFeedbackItemsCount === 0) {
                return { 
                    labelId: label.feedbackLabelId,
                    label: label.description,
                    optionId: value !== '' ? value : -1,
                    parents: updatedParents
                };
            } else {
                return underlyingFeedbackRef.current.getValue(updatedParents);
            }
        }

    }));

    return (
        <>
            <FormControl fullWidth required={label.required} style={{marginBottom: 15}} error={!isValid}>
                <InputLabel id={uid} style={{marginLeft: `${label.level * 15}px`}}>{lookUpTranslatedFeedbackLabel(label)}</InputLabel>
                <Select 
                    labelId={uid} 
                    label={lookUpTranslatedFeedbackLabel(label)} 
                    value={value || ''} 
                    onChange={onChange}
                    style={{marginLeft: `${label.level * 15}px`}}>
                    <MenuItem value=''></MenuItem>
                    {options?.map(option => <MenuItem key={option.feedbackOptionId} value={option.feedbackOptionId}>{lookUpTranslatedFeedbackOption(option)}</MenuItem>)}
                </Select>
            </FormControl>
            {<FeedbackView ref={underlyingFeedbackRef} parentId={value} items={underlyingFeedbackItems} feedback={feedback} onRequiredChange={onRequiredChange} />}
        </>
    );

});

export default FeedbackDropdown;