import React, { useEffect, useState, useRef, useCallback, useReducer } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../utils/contexts/appContext';
import { useApplicationParams } from '../../../hooks/app-params';
import db from '../../../utils/dexie/driverappdb';
import queries from '../../../utils/dexie/queries';
import SignaturePad from 'react-signature-pad-wrapper';
import utils from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { motion } from "framer-motion";

import './Job.css';
import { Dialog, DialogContent, DialogActions, FormControlLabel, Checkbox, DialogTitle, Chip, Fade, Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import Remarks from '../../../components/remarks';
import ImageLibrary from '../../../components/imageLibrary';
import Feedback from '../../../components/feedback';
import hexToRgba from 'hex-to-rgba';
import ArticlesManager from './articlesManager';
import { ExpandMore } from '@material-ui/icons';
import Loader from '../../../components/loader/Loader';
import NotFound from '../../../components/notFound/NotFound';
import { usePlanItemStatusManagement } from '../../../hooks/plan-item-status-management';
import { PlanningItemType } from '../../../utils/enums/planning-item-type';
import { PlanningItemStatus } from '../../../utils/enums/planning-item-status';
import { useSinglePress } from '../../../hooks/single-press';
import { DropDetailsRepository } from '../../../utils/dexie/repositories/drop-details-repository';
import { DropRepository } from '../../../utils/dexie/repositories/drop-repository';
import { ProjectTypeRepository } from '../../../utils/dexie/repositories/project-type-repository';

const pageVariants = {
    initial: {
        opacity: 0,
        x: "100vh"
    },
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0,
        x: "100vh"
    }
};

const pageTransition = {
    duration: 0.2
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    actionButton: {
        color: theme.palette.success.main,
        borderColor: hexToRgba(theme.palette.success.main, .5)
    },
    panel: {
        flexGrow: 1,
        backgroundColor: "#ffffff !important"
    },
    tabPanel: {
        paddingTop: 15
    },
    errorLabel: {
        color: "#FF0000 !important"
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const initialValidationState = {
    remarks: true,
    signature: true,
    feedback: true
};

const TabItem = ({
    label,
    value,
    index,
    icon,
    ...otherProps
}) => {
    const isMobileLimit = 800;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= isMobileLimit);

    useEffect(() => {
        const onWindowResize = () => setIsMobile(window.innerWidth <= isMobileLimit);

        window.addEventListener('resize', onWindowResize);

        return () => window.removeEventListener('resize', onWindowResize);
    }, []);

    return (
        <Tab
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`} 
            style={{ minWidth: "20%" }}
            label={!isMobile ? label : undefined}
            value={value}
            icon={isMobile ? icon : undefined}
            {...otherProps} />
    );
};

function Job() {
    let { dropId } = useParams();
    let history = useHistory();
    const location = useLocation();
    const [, dispatch] = useAppContext();
    const appParams = useApplicationParams();
    const classes = useStyles();
    const [t] = useTranslation();
    const [visibleTab, setVisibleTab] = useState("general");
    const inputFileRef = useRef(null);
    const internalImageInputFileRef = useRef(null);
    const [dialogInterruptedRequestIsOpen, setDialogInterruptedRequestIsOpen] = useState(false);
    const [dialogDoneRequestIsOpen, setDialogDoneRequestIsOpen] = useState(false);
    const [activeElevatorAtArrival, setActiveElevatorAtArrival] = useState(false);
    const [activeElevatorAtDeparture, setActiveElevatorAtDeparture] = useState(false);
    const [expandedAccordion, setExpandedAccordion] = useState('remarks');
    const [isFeedbackRequired, setIsFeedbackRequired] = useState(false);
    const [isRemarksRequired, setIsRemarksRequired] = useState(false);

    const [showValidationSummary, setShowValidationSummary] = useState(false);
    const [validation, dispatchValidation] = useReducer((state, validationResult) => {
        switch (validationResult) {
            case 'reset':
                return initialValidationState;
            default:
                return {
                    ...state,
                    [validationResult.key]: validationResult.valid
                };
        }
    }, initialValidationState);

    const driverRemarks = useRef();
    const imageLibrary = useRef();
    const internalImageLibrary = useRef();
    const articlesManager = useRef();
    const feedbackRef = useRef();

    const [drop, setDrop] = useState();
    const [dropLocationName, setDropLocationName] = useState('');
    const [signaturePad, setSignaturePad] = useState();
    const [projectType, setProjectType] = useState();

    const [showArticlesTab, setShowArticlestab] = useState(false);
    const useSignature = appParams?.alwaysSignOnGlass || drop?.signonglass;

    const isLoading = drop === undefined;

    // Initial render
    useEffect(() => {

        async function init() {
            const [hasDropDetailsQueryResult, dropQueryResult] = await Promise.all([
                DropDetailsRepository.hasDropDetails(parseInt(dropId)),
                DropRepository.getDrop(parseInt(dropId))
            ]);

            const projectTypeQueryResult = await Promise.resolve(dropQueryResult?.projectType > 0 ? ProjectTypeRepository.getProjectType(parseInt(dropQueryResult.projectType)) : null);
            
            if (signaturePad != null && signaturePad.current && dropQueryResult?.signature != null) {
                signaturePad.current.fromDataURL(dropQueryResult.signature);
            }

            setDrop(dropQueryResult);
            setDropLocationName(dropQueryResult?.location?.name ?? '');
            setProjectType(projectTypeQueryResult);
            setShowArticlestab(appParams?.articleInventory || hasDropDetailsQueryResult);

            if (location.state != null && location.state.selectedTab != null) {
                setVisibleTab(location.state.selectedTab);
                // Delete the state to prevent being stuck on the redirectionTab after rerenders.
                delete location.state.selectedTab;
            }
        }

        init();

    }, [dropId, signaturePad, appParams?.articleInventory, location.state]);

    /**
     * Currently using PlanItemStatusManagement to update the status of the planning item until we've rewritten this screen.
     * The hook 'usePlanItemStatusManagement' contains dispatch logic to dispatch a change of the planningitem status up to the RoutePlanningContext,
     * which is necessary inside the routeplanning screen. This need to be reanalyzed, once the job screens are rewritten, 
     * to determine if we still need to rely on this hook from inside those screens, or if we should use separated logic to handle the status of a planning item 
     * on those screens (the RoutePlanningContext is actually only for the routeplanning screen).
     */
    const updateRoutePlanningItemStatus = usePlanItemStatusManagement(drop?.routeId, PlanningItemType.DROP, parseInt(dropId));

    /**
     * This method can be used to update the local data of the drop without it will be send to the server.
     * @param {*} newStatus 
     */
    const updateDropData = useCallback((newStatus) => {
        return new Promise((resolve, reject) => {
            try {

                if (useSignature && signaturePad != null && signaturePad.current != null) {
                    const newSignature = signaturePad.current.isEmpty() ? null : signaturePad.current.toDataURL();
                    db.dropdata.update(parseInt(dropId), {
                        signature: newSignature
                    });

                    setDrop(drop => ({ ...drop, signature: newSignature }));
                }
    
                // Save the remarks first
                if (driverRemarks != null && driverRemarks.current != null) {
                    drop.driverRemarks = driverRemarks.current.save();
                }

                // Save the feedback first
                if (feedbackRef != null && feedbackRef.current != null) {
                    feedbackRef.current.save();
                }
                
                // Save the articles first
            	if (articlesManager != null && articlesManager.current != null) {
                	articlesManager.current.save();
            	}
                
                if (newStatus) {
    
                    // lat / lon invullen
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((location) => {
                            db.dropdata.update(parseInt(dropId), {
                                status: newStatus,
                                lat: location.coords.latitude,
                                lon: location.coords.longitude
                            });
                            resolve();
                        }, (error) => {
                            // No access allowed to location
                            db.dropdata.update(parseInt(dropId), {
                                status: newStatus,
                                lat: 0,
                                lon: 0
                            });
                            resolve();
                        },
                        { 
                            enableHighAccuracy: true,
                            timeout: 1000
                        });
                    } else {
                        // The browser doesn't support Location
                        db.dropdata.update(parseInt(dropId), {
                            status: newStatus,
                            lat: 0,
                            lon: 0
                        });
                        resolve();
                    }
    
                } else {
                    resolve();
                }
            } catch (error) {
                queries.addErrorToLogs(error);
                history.push('/error');
                reject(error);
            }
        });
    }, [dropId, drop, history, signaturePad, useSignature]);

    useEffect(() => {
        if (drop != null) {
            dispatch({ 
                type: 'changeHeader', 
                header: { 
                    title: `${t("Complete")} ${dropLocationName}`, 
                    child: true,
                    onNavigation: updateDropData
                } 
            });
        }
    }, [dispatch, t, drop, dropLocationName, updateDropData]);

    const isAccordionExpanded = (id) => expandedAccordion === id;

    const handleChangeAccordion = (id) => (e, isExpanded) => {
        updateDropData()
            .finally(() => setExpandedAccordion(isExpanded ? id : false));
    };

    const onSelectedFilesChanged = (e, internalImages = false) => {
        try {
            const libraryRef = internalImages ? internalImageLibrary : imageLibrary;
            if (libraryRef != null && libraryRef.current != null) {
                if (e.target.files) {
                    libraryRef.current.addImages(e.target.files);
                }
            } else {
                throw new Error("[JobView.js] No image library detected on this page");
            }
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const updateDropStatus = (actionType, status) => {
        try {
            let now = new Date();
            var utc = now.getTime() - now.getTimezoneOffset() * 60000;

            var dropAction = { type: actionType, key: parseInt(dropId), time: utc };
            if (inputRequiredBeforeLeaving()) {
                dropAction.activeAtArrival = activeElevatorAtArrival;
                dropAction.activeAtDeparture = activeElevatorAtDeparture;
            }

            return queries.isActionAllowed(dropAction)
                .then(allowed => {
                    if (allowed) {
                        db.drops.get(parseInt(dropId)).then((drop) => {
                            db.routes.update(parseInt(drop.routeId), { delay: utils.calculateDelay(new Date(drop.eta), now) });
                        });
        
                        return updateDropData(status)
                            .then(async () => {
                                let planningItemStatus;
                                switch (actionType) {
                                    case "DropInterrupted":
                                        planningItemStatus = PlanningItemStatus.TO_DO;
                                        break;
                                    case "DropFinish":
                                        planningItemStatus = PlanningItemStatus.FINISHED;
                                        break;
                                    default:
                                        break;
                                }

                                if (planningItemStatus != null) {
                                    await updateRoutePlanningItemStatus(planningItemStatus);
                                }
                                
                                await db.actions.put(dropAction);
                            });
                    }
                });

        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const handleClear = () => {
        signaturePad.current.clear();
    }

    const handleOnBegin = () => {
        let textAreas = document.getElementsByTagName("textarea");
        for (var i=0; i < textAreas.length; i++) {
            textAreas[i].blur();
        }
    }

    const handleClickOpenPhotoDialog = (e, internalImages = false) => {
        const libraryRef = internalImages ? internalImageInputFileRef : inputFileRef;
        libraryRef.current.click();
    };

    const handleTabChange = (event, newTab) => {
        updateDropData()
            .finally(() => setVisibleTab(newTab));
    };

    const navigateToInformation = () => {
        updateDropData()
            .finally(() => history.push(`/routes/timeline/info/${dropId}`));
    }

    const inputRequiredBeforeLeaving = () => {
        // Show the input dialog if the param is set and the projectType is Maintenance (1) or Assistence (2).
        return appParams.activeAtArrivalAndDeparture && projectType && [1, 2].includes(projectType.id)
    }

    const resetInputBeforeDeparture = () => {
        if (projectType) {
            // Default true if the type of the project is "maintenance"
            setActiveElevatorAtArrival(projectType.id === 1);
            // Default true if the type of the project is "maintenance" or "assistance"
            setActiveElevatorAtDeparture(projectType.id === 1 || projectType.id === 2);
        }
    }

    const handleInterruptedRequest = useSinglePress(async () => {
        if (validate()) {
            if (inputRequiredBeforeLeaving()) {
                resetInputBeforeDeparture();
                setDialogInterruptedRequestIsOpen(true);
            } else {
                await handleInterrupted();
            }
        } else {
            setShowValidationSummary(true);
            setTimeout(() => setShowValidationSummary(false), 3000);
        }
    });

    const handleInterrupted = useSinglePress(async () => {
        await updateDropStatus("DropInterrupted", 70)
            .then(() => {
                setDialogInterruptedRequestIsOpen(false);
                history.replace(`/routes/${drop.routeId}`);
            });
    });

    const handleDoneRequest = useSinglePress(async () => {
        if (validate()) {
            if (inputRequiredBeforeLeaving()) {
                resetInputBeforeDeparture();
                setDialogDoneRequestIsOpen(true);
            } else {
                await handleDone();
            }
        } else {
            setShowValidationSummary(true);
            setTimeout(() => setShowValidationSummary(false), 3000);
        }
    });

    const handleDone = useSinglePress(async () => {
        await updateDropStatus("DropFinish", 40)
            .then(() => {
                setDialogDoneRequestIsOpen(false);
                history.replace(`/routes/${drop.routeId}`);
            });
    });

    const clearValidation = () => dispatchValidation('reset');

    const validate = useCallback((silenceMode) => {
        clearValidation();

        let driverRemarksValid = true;
        if (driverRemarks != null && driverRemarks.current != null)
            driverRemarksValid = driverRemarks.current.validate(silenceMode);

        // Dispatch the validation to update the UI
        dispatchValidation({ key: "remarks", valid: driverRemarksValid });

        let signatureValid = (useSignature && appParams?.signOnGlassRequired) ? 
            (signaturePad != null && signaturePad.current != null && !signaturePad.current.isEmpty()) : 
            true;

        // Dispatch the validation to update the UI
        dispatchValidation({ key: "signature", valid: signatureValid });

        let feedbackValid = validateFeedback(silenceMode);

        return driverRemarksValid && signatureValid && feedbackValid;
    }, [signaturePad, useSignature, appParams?.signOnGlassRequired]);

    const validateFeedback = (silenceMode) => {
        let feedbackValid = (feedbackRef != null && feedbackRef.current != null) ? 
            feedbackRef.current.validate(silenceMode) : true;

        // Dispatch the validation to update the UI
        dispatchValidation({ key: "feedback", valid: feedbackValid });

        return feedbackValid;
    };

    const onSignatureRefChange = useCallback(node => {
        setSignaturePad({ current: node }); // Change ref state to trigger re-render
    }, []);
    
    return (
        <>
            {
                isLoading ?
                    <Loader /> :
                    (
                        drop == null ?
                            <NotFound /> :
                            (
                                <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                                    <div className={classes.panel}>
                                        <AppBar position="static" className={classes.panel} style={{ boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)" }}>
                                            <Tabs value={visibleTab} onChange={handleTabChange} variant="fullWidth" indicatorColor="primary" textColor="primary">
                                                <TabItem label={t("General")} index={0} value="general" icon={<FontAwesomeIcon icon={["fas", "info-circle"]} size="lg" />} />
                                                <TabItem label={t("Pictures")} index={1} value="images" icon={<FontAwesomeIcon icon={["far", "camera-alt"]} size="lg" />} />
                                                {appParams.onlyInternalUsageImages ? <TabItem label={t("InternalPictures")} index={1} value="images_internal" icon={<FontAwesomeIcon icon={["far", "camera-home"]} size="lg" />} /> : null}
                                                {showArticlesTab ? <TabItem label={t("Articles")} index={2} value="articles" icon={<FontAwesomeIcon icon={["fas", "shopping-cart"]} size="lg" />} /> : null}
                                            </Tabs>
                                        </AppBar>
                                        <TabPanel value={visibleTab} index="general">
                                            <div className="global-container">
                                                <Accordion 
                                                    expanded={isAccordionExpanded('remarks')} 
                                                    onChange={handleChangeAccordion('remarks')} >
                                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                                        <Typography variant='subtitle1' className={`form-label ${validation.remarks ? '' : classes.errorLabel}`}>
                                                            {t('Remarks')} {isRemarksRequired ? '*' : ''}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Remarks ref={driverRemarks} remarks={drop.driverRemarks} onRequiredChange={(required) => setIsRemarksRequired(required)} />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {
                                                    useSignature ? 
                                                        <Accordion 
                                                            expanded={isAccordionExpanded('signature')} 
                                                            onChange={handleChangeAccordion('signature')}>
                                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                                <Typography variant='subtitle1' className={`form-label ${validation.signature ? '' : classes.errorLabel}`}>
                                                                    {t('Signature')} {appParams.signOnGlassRequired ? '*' : ''}
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <div style={{ paddingTop: "15px", width: '100%' }}>
                                                                    <Card>
                                                                        <CardContent>
                                                                            <SignaturePad redrawOnResize={true} options={{ 
                                                                                    onBegin: handleOnBegin
                                                                                }} ref={onSignatureRefChange} />
                                                                        </CardContent>
                                                                        <CardActions>
                                                                            <Button onClick={handleClear} size="small" color="primary">
                                                                                {t("Reset")}
                                                                            </Button>
                                                                        </CardActions>
                                                                    </Card>
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion> : <></>
                                                }
                                                {
                                                    // Only show for projecttype "Assistance"
                                                    projectType && projectType.id === 2 ?
                                                        <Accordion 
                                                            expanded={isAccordionExpanded('feedback')} 
                                                            onChange={handleChangeAccordion('feedback')}>
                                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                                <Typography variant='subtitle1' className={`form-label ${validation.feedback ? '' : classes.errorLabel}`}>
                                                                    {t('Feedback')} {isFeedbackRequired ? '*' : ''}
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Feedback ref={feedbackRef} dropId={parseInt(dropId)} feedbackKey='CODES_PANNE' onRequiredChange={(required) => setIsFeedbackRequired(required)} />
                                                            </AccordionDetails>
                                                        </Accordion> : <></>
                                                }
                                            </div>
                                            <div className="job-buttons">
                                                <Grid container>
                                                    <Grid item md={4}></Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Button onClick={navigateToInformation} fullWidth variant="outlined" color="primary">
                                                                    <FontAwesomeIcon icon={["fad", "info"]} />
                                                                    <span className="button-text-with-icon">{t("Info")}</span>
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button onClick={handleInterruptedRequest} fullWidth variant="outlined" style={{ borderColor: "#e74c3c", color: "#e74c3c" }}>
                                                                    <FontAwesomeIcon icon={["fad", "ban"]} />
                                                                    <span className="button-text-with-icon">{t("Interrupt")}</span>
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button onClick={handleDoneRequest} fullWidth variant="outlined" className={classes.actionButton}>
                                                                    <FontAwesomeIcon icon={["fad", "check"]} />
                                                                    <span className="button-text-with-icon">{t("Done")}</span>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={4}></Grid>
                                                </Grid>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={visibleTab} index="images">
                                            <div className="global-container">
                                                <Typography variant="h6">{t('Pictures')}</Typography>
                                                <Box style={{marginTop: 15}}>
                                                    <div>
                                                        <input type="file" accept="image/jpeg,image/png,image/bmp" onChange={onSelectedFilesChanged} className="jobview-inputfile" multiple="multiple" ref={inputFileRef} />
                                                    </div>

                                                    <div style={{ paddingBottom: "15px" }}>
                                                        <Button onClick={(e) => handleClickOpenPhotoDialog(e, false)} variant="outlined" color="primary" autoFocus>
                                                            <FontAwesomeIcon icon={["fad", "images"]} />
                                                            <span className="button-text-with-icon" style={{ paddingLeft: "10px" }}>{t("ChoosePicture")}</span>
                                                        </Button>
                                                    </div>
                                                    <ImageLibrary 
                                                        ref={imageLibrary}
                                                        dropId={dropId} 
                                                        isEditable={true} 
                                                        internalImages={false}
                                                        onDeleteCallback={() => {
                                                            // Make sure we reset the value of the inputFileRef so the onChangeHandler will be triggered if necessary
                                                            inputFileRef.current.value = "";
                                                        }} />
                                                </Box>
                                            </div>
                                        </TabPanel>
                                        {
                                            appParams.onlyInternalUsageImages ? 
                                                <TabPanel value={visibleTab} index="images_internal" className={classes.tabPanel}>
                                                    <div className="global-container">
                                                        <Typography variant="h6">{t('InternalPictures')}</Typography>
                                                        <Box style={{marginTop: 15}}>
                                                            <div>
                                                                <input type="file" accept="image/jpeg,image/png,image/bmp" onChange={(e) => onSelectedFilesChanged(e, true)} className="jobview-inputfile" multiple="multiple" ref={internalImageInputFileRef} />
                                                            </div>
                                                            <div style={{ paddingBottom: "15px" }}>
                                                                <Button onClick={(e) => handleClickOpenPhotoDialog(e, true)} variant="outlined" color="primary" autoFocus>
                                                                    <FontAwesomeIcon icon={["fad", "images"]} />
                                                                    <span className="button-text-with-icon" style={{ paddingLeft: "10px" }}>{t("ChoosePicture")}</span>
                                                                </Button>
                                                            </div>
                                                            <ImageLibrary 
                                                                ref={internalImageLibrary}
                                                                dropId={dropId} 
                                                                isEditable={true} 
                                                                internalImages={true}
                                                                onDeleteCallback={() => {
                                                                    // Make sure we reset the value of the inputFileRef so the onChangeHandler will be triggered if necessary
                                                                    internalImageInputFileRef.current.value = "";
                                                                }} />
                                                        </Box>
                                                    </div>
                                                </TabPanel> : null
                                        }
                                        <TabPanel value={visibleTab} index="articles" className={classes.tabPanel}>
                                            <ArticlesManager ref={articlesManager} dropId={parseInt(dropId)} 
                                                catalogState={{ 
                                                    redirectionUrl: `/routes/timeline/job/${dropId}`,
                                                    redirectionTab: visibleTab 
                                                }} />
                                        </TabPanel>
                                        <Dialog open={dialogInterruptedRequestIsOpen}>
                                            <DialogTitle>{t("Interrupt")}</DialogTitle>
                                            <DialogContent>
                                                <FormControlLabel 
                                                    control = {
                                                        <Checkbox 
                                                            checked={activeElevatorAtArrival}
                                                            onChange={(event) => setActiveElevatorAtArrival(event.target.checked)} 
                                                            color="primary"/>
                                                    }
                                                    label = {t("ActiveElevatorAtArrival")}
                                                />
                                                <FormControlLabel 
                                                    control = {
                                                        <Checkbox 
                                                            checked={activeElevatorAtDeparture}
                                                            onChange={(event) => setActiveElevatorAtDeparture(event.target.checked)}
                                                            color="primary" />   
                                                    }
                                                    label={t("ActiveElevatorAtDeparture")}
                                                    />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setDialogInterruptedRequestIsOpen(false)} color="primary">{t("Cancel")}</Button>
                                                <Button onClick={handleInterrupted} color="primary">{t("Confirm")}</Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Dialog open={dialogDoneRequestIsOpen}>
                                            <DialogTitle>{t("Done")}</DialogTitle>
                                            <DialogContent>
                                                <FormControlLabel 
                                                    control = {
                                                        <Checkbox 
                                                            checked={activeElevatorAtArrival}
                                                            onChange={(event) => setActiveElevatorAtArrival(event.target.checked)} 
                                                            color="primary"/>
                                                    }
                                                    label = {t("ActiveElevatorAtArrival")}
                                                />
                                                <FormControlLabel 
                                                    control = {
                                                        <Checkbox 
                                                            checked={activeElevatorAtDeparture}
                                                            onChange={(event) => setActiveElevatorAtDeparture(event.target.checked)}
                                                            color="primary" />   
                                                    }
                                                    label={t("ActiveElevatorAtDeparture")}
                                                    />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setDialogDoneRequestIsOpen(false)} color="primary">{t("Cancel")}</Button>
                                                <Button onClick={handleDone} color="primary">{t("Confirm")}</Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Fade in={showValidationSummary}>
                                            <Box textAlign="center">
                                                <Chip label={t("ValidationErrorsOccured")} style={{ borderColor: "#e74c3c", backgroundColor: "#e74c3c", color: "#FFF", marginTop: 15, marginBottom: 15 }} />
                                            </Box>
                                        </Fade>
                                    </div>
                                </motion.div>
                            )
                    )
            }
        </>
    );
}

export default Job;
