import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppContext } from '../../../utils/contexts/appContext';
import db from '../../../utils/dexie/driverappdb';
import Loader from '../../../components/loader';
import ImageLibrary from '../../../components/imageLibrary';

import './Info.css';

import DownloadButton from '../../../components/downloadButton';
import FilterButton from '../../../components/filterButton';

import { AuthContext } from "../../../providers/AuthProvider";
import queries from '../../../utils/dexie/queries';
import { FontAwesomeDuoToneStyle } from '../../../fontawesome';
import History from '../../../components/history';
import RemarksOverview from '../../../components/remarksOverview/RemarksOverview';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import ArticlesManager from '../job/articlesManager';
import NotFound from '../../../components/notFound/NotFound';

const DefaultLeafletMarker = L.icon({
    iconUrl: markerIcon,
    iconSize: [25, 41],
    shadowUrl: markerIconShadow,
    iconAnchor: [12, 41]
});

// For some reason, React-leaflet does not include the marker images anymore.
// To fix this, we need to reset the default icon images of Leaflet.
L.Marker.prototype.options.icon = DefaultLeafletMarker;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#ffffff !important"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    actionLink: {
        color: theme.palette.primary.main
    }
}));

const pageVariants = {
    initial: {
        opacity: 0,
        x: "100vh"
    },
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0,
        x: "100vh"
    }
};

const pageTransition = {
    duration: 0.2
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const FileList = ({ drop }) => {
    const [t] = useTranslation();
    const auth = useContext(AuthContext);
    const [files, setFiles] = useState([]);
    const [filteredFiles, setFilteredFiles] = useState([]); 

    useEffect(() => {
        if (drop.projectId) {
            auth.getUser().then(async (user) => {
                if (user != null) {
                    await auth.fetch('get', `projectfiles?${drop.mainProjectId ? ("mainProjectId=" + drop.mainProjectId) : ("projectId=" + drop.projectId)}`)
                        .then(async (result) => {
                            if (result && result.status === 200) {
                                let files = result.data.sort((f1 ,f2) => f1.creationDate < f2.creationDate ? 1 : -1);
                                setFiles(files);
                                setFilteredFiles(files);
                            }
                        });
                }
            });
        }
        
    }, [auth, drop.projectId, drop.mainProjectId]);

    const getFormattedDate = (date) => {
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }

    const filterFiles = (filter) => {
        setFilteredFiles(
            files.filter(file => {
                if (filter.text === '')
                    return true;
                let fileFilter = filter.text.toLowerCase();
                return file.subject.toLowerCase().includes(fileFilter) || file.description.toLowerCase().includes(fileFilter);
            })
        );
    };

    return (
        <>
            {
                (!navigator.onLine || files.length === 0) ?
                    <div>{ t("NoFilesAvailable") }</div> :
                    <>
                        <Box display="flex" justifyContent="flex-end" style={{ marginBottom: '10px' }}>
                            <Box>
                                <FilterButton onChange={filterFiles} />
                            </Box>
                        </Box>
                        <div className="kt-widget4">
                        { 
                            filteredFiles
                                .map(file => 
                                    <div key={file.projectFileId} className="kt-widget4__item">
                                        <div className="kt-widget4__pic kt-widget4__pic--pic">
                                            <FontAwesomeIcon icon={["fad", "file-alt"]} size="3x" style={FontAwesomeDuoToneStyle} />
                                        </div>
                                        <div className="kt-widget4__info">
                                            <div className="kt-widget4__username">
                                                {file.subject}
                                            </div>
                                            <div className="kt-widget4__text">
                                                {file.description}<br />
                                                {getFormattedDate(new Date(file.creationDate))}
                                            </div>
                                        </div>
                                        <div>
                                            <DownloadButton url={`projectfiles/${file.projectFileId}`} />
                                        </div>
                                    </div>
                                )
                        }
                        </div>
                    </>
            }
        </>
    );
}

function Info() {
    let { dropId } = useParams();
    const [, dispatch] = useAppContext();
    const [drop, setDrop] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const classes = useStyles();
    const [t] = useTranslation();
    const [visibleTab, setVisibleTab] = useState("general");
    delete L.Icon.Default.prototype._getIconUrl;
    const [isMobile, setIsMobile] = useState(false);
    const [appParams, setAppParams] = useState([]);

    let isMobileLimit = 800;
    let useSignature = appParams.alwaysSignOnGlass || (drop != null && drop.signonglass);

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    const handleTabChange = (event, newTab) => {
        setVisibleTab(newTab);
    };

    const isNullOrWhiteSpace = (value) => {
        return value == null || value.match(/^\s*$/) !== null;
    }

    const getFormattedPhoneNumber = (phoneNumber) => {
        if (phoneNumber == null)
            return phoneNumber;
        return phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
    };

    useEffect(() => {
        setIsMobile(window.innerWidth <= isMobileLimit);
        window.addEventListener("resize", () => setIsMobile(window.innerWidth <= isMobileLimit));

        db.transaction('r', db.drops, db.dropdata, db.locations, db.driverremarks, db.skipremarks, db.params, db.deliveryorderdetails, db.deliveryorderdetailsdata, async () => {
            setAppParams(await db.params.where('id').notEqual(0).first());
            
            queries.joinDropData(
                await db.drops.where({ id: parseInt(dropId) })
                    .with({ 
                        location: 'location', 
                        driverRemarks: 'driverremarks',
                        skipRemarks: 'skipremarks',
                        deliveryorderdetails: 'deliveryorderdetails'
                    })
            ).then(async (result) => {
                if (result.length > 0) {
                    let drop = result[0];
                    setDrop(drop);
                    dispatch({ type: 'changeHeader', header: { title: `Info ${drop.location?.name ?? ''}`, child: true } });
                }
            });
        }).then(() => {
            setIsLoading(false);
        });
    }, [dispatch, dropId, isMobileLimit]);

    return (
        <>
            {
                isLoading ?
                    <><Loader /></> :
                    (
                        drop == null ?
                            <NotFound /> :
                            (
                                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                                    <div className={classes.root}>
                                        <AppBar position="static" className={classes.root} style={{ boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)" }}>
                                            <Tabs value={visibleTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto"
                                                indicatorColor="primary" textColor="primary">
                                                {
                                                    isMobile ?
                                                        <Tab icon={<FontAwesomeIcon icon={["fas", "info-circle"]} size="lg" />} style={{ minWidth: "20%" }} value="general" /> :
                                                        <Tab label={t("General")} style={{ minWidth: "20%" }} value="general" />
                                                }
                                                {
                                                    useSignature &&
                                                        (isMobile ?
                                                            <Tab icon={<FontAwesomeIcon icon={["fas", "signature"]} size="lg" />} style={{ minWidth: "20%" }} value="signature" /> :
                                                            <Tab label={t("Signature")} style={{ minWidth: "20%" }} value="signature" />)
                                                }
                                                {
                                                    isMobile ?
                                                        <Tab icon={<FontAwesomeIcon icon={["far", "camera-alt"]} size="lg" />} style={{ minWidth: "20%" }} value="images" /> :
                                                        <Tab label={t("Pictures")} style={{ minWidth: "20%" }} value="images" />
                                                }
                                                {
                                                    appParams.onlyInternalUsageImages ?
                                                        (isMobile ?
                                                            <Tab icon={<FontAwesomeIcon icon={["far", "camera-home"]} size="lg" />} style={{ minWidth: "20%" }} value="images_internal" /> :
                                                            <Tab label={t("InternalPictures")} style={{ minWidth: "20%" }} value="images_internal" />) : null
                                                }
                                                {
                                                    isMobile ?
                                                        <Tab icon={<FontAwesomeIcon icon={["fas", "folder-open"]} size="lg" />} style={{ minWidth: "20%" }} value="files" /> :
                                                        <Tab label={t("Files")} style={{ minWidth: "20%" }} value="files" />
                                                }
                                                {
                                                    drop.mainProjectId && 
                                                        (isMobile ?
                                                            <Tab icon={<FontAwesomeIcon icon={["fas", "history"]} size="lg" />} style={{ minWidth: "20%" }} value="history" /> :
                                                            <Tab label={t("History")} style={{ minWidth: "20%" }} value="history" />)
                                                }
                                                {
                                                    isMobile ?
                                                        <Tab icon={<FontAwesomeIcon icon={["fas", "shopping-cart"]} size="lg" />} style={{ minWidth: "20%" }} value="articles" /> :
                                                        <Tab label={t("Articles")} style={{ minWidth: "20%" }} value="articles" />
                                                }
                                            </Tabs>
                                        </AppBar>
                                        <TabPanel value={visibleTab} index="general">
                                            <div className="global-container">
                                                <div className="info-general-item">
                                                    <div className="info-general-icon">
                                                        <div>
                                                            <FontAwesomeIcon icon={["fad", "home"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>{drop.location?.name}</div>
                                                        <div>{drop.location?.address}</div>
                                                    </div>
                                                </div>
                                                {
                                                    !isNullOrWhiteSpace(drop.email) ?
                                                        <>
                                                            {
                                                                drop.email.split(';')
                                                                    .map((email, index) => (
                                                                        <div key={`email-info-${index}`} className="info-general-item">
                                                                            <div className="info-general-icon">
                                                                                <div>
                                                                                    <FontAwesomeIcon icon={["fad", "at"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <a href={`mailto:${email}`} className={classes.actionLink}>{email}</a>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                            }
                                                        </> : <></>
                                                }
                                                {
                                                    !isNullOrWhiteSpace(drop.mobilePhone) ?
                                                        <div className="info-general-item">
                                                            <div className="info-general-icon">
                                                                <div>
                                                                    <FontAwesomeIcon icon={["fad", "phone"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <a href={`tel:${getFormattedPhoneNumber(drop.mobilePhone)}`} className={classes.actionLink}>{getFormattedPhoneNumber(drop.mobilePhone)}</a>
                                                            </div>
                                                        </div> : <></>
                                                }
                                                <div className="info-general-item">
                                                    <div className="info-general-icon">
                                                        <div>
                                                            <FontAwesomeIcon icon={["fad", "clock"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            drop.tw !== null
                                                                ? <div>{drop.tw}</div>
                                                                : <div>{t("NoTimewindowAvailable")}</div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    !isNullOrWhiteSpace(drop.jobInstruction) ? 
                                                        <div className="info-general-item">
                                                            <div className="info-general-icon">
                                                                <div>
                                                                    <FontAwesomeIcon icon={["fad", "clipboard"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                                                </div>
                                                            </div>
                                                            <div style={{whiteSpace: 'pre-wrap'}}>{drop.jobInstruction}</div>
                                                        </div>
                                                        : <></>
                                                }
                                                <div className="info-general-item">
                                                    <div className="info-general-icon">
                                                        <div>
                                                            <FontAwesomeIcon icon={["fad", "info-circle"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            drop.remark !== null
                                                                ? <div dangerouslySetInnerHTML={{ __html: drop.remark }}></div>
                                                                : <div>{t("NoRemarkAvailable")}</div>
                                                        }
                                                    </div>
                                                </div>
                                                <RemarksOverview remarks={drop.status === 50 ? drop.skipRemarks : drop.driverRemarks} nonEmptyOnly />

                                                <div className="padding-bottom-20" style={{paddingTop: 20}}>
                                                    <MapContainer center={[drop.location?.lat ?? 0, drop.location?.lon ?? 0]} zoom={13} zoomControl={false} attributionControl={false}>
                                                        <TileLayer
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                                        />
                                                        <Marker position={[drop.location?.lat ?? 0, drop.location?.lon ?? 0]}></Marker>
                                                    </MapContainer>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {
                                            useSignature &&
                                                <TabPanel value={visibleTab} index="signature">
                                                    <div className="global-container">
                                                        {
                                                            drop.signature !== null
                                                                ? <div><img alt="signature" width="100%" src={drop.signature}></img></div>
                                                                : <div>{t("NoSignatureAvailable")}</div>
                                                        }
                                                    </div>
                                                </TabPanel>
                                        }
                                        <TabPanel value={visibleTab} index="images">
                                            <div className="global-container">
                                                <Typography variant="h6">{t('Pictures')}</Typography>
                                                <Box>
                                                    <ImageLibrary dropId={drop.id} onlineImagesEnabled={true} />
                                                </Box>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={visibleTab} index="images_internal">
                                            <div className="global-container">
                                                <Typography variant="h6">{t('InternalPictures')}</Typography>
                                                <Box>
                                                    <ImageLibrary dropId={drop.id} onlineImagesEnabled={true} internalImages={true} />
                                                </Box>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={visibleTab} index="files">
                                            <div className="global-container">
                                                <FileList drop={drop} />
                                            </div>
                                        </TabPanel>
                                        {
                                            drop.mainProjectId ?
                                                <TabPanel value={visibleTab} index="history">
                                                    <div className="global-container">
                                                        <History mainProjectId={drop.mainProjectId} />
                                                    </div>
                                                </TabPanel> : <></>
                                        }
                                        <TabPanel value={visibleTab} index="articles">
                                            <div className="global-container">
                                                <ArticlesManager readonly dropId={dropId} />
                                            </div>
                                        </TabPanel>
                                        {
                                            /*<TabPanel value={value} index={4}>
                                                <div className="global-container" style={{ paddingTop: "0px" }}>
                                                    {t("NoChecklistAvailable")}
                                                    {<div className="frame">
                                                        <div className="list">
                                                            <ul>
                                                                <li>
                                                                    <input type="checkbox" id="item-1" name="item-1" />
                                                                    <label htmlFor="item-1" className="text">Create a list</label>
                                                                    <label htmlFor="item-1" className="button"></label>
                                                                    <svg width="15px" height="10px" className="checkmark">
                                                                        <polyline points="1,5 6,9 14,1" />
                                                                    </svg>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" id="item-2" name="item-2" />
                                                                    <label htmlFor="item-2" className="text">Complete first task</label>
                                                                    <label htmlFor="item-2" className="button"></label>
                                                                    <svg width="15px" height="10px" className="checkmark">
                                                                        <polyline points="1,5 6,9 14,1" />
                                                                    </svg>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" id="item-3" name="item-3" />
                                                                    <label htmlFor="item-3" className="text">Write some CSS code</label>
                                                                    <label htmlFor="item-3" className="button"></label>
                                                                    <svg width="15px" height="10px" className="checkmark">
                                                                        <polyline points="1,5 6,9 14,1" />
                                                                    </svg>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" id="item-4" name="item-4" />
                                                                    <label htmlFor="item-4" className="text">Amaze the world</label>
                                                                    <label htmlFor="item-4" className="button"></label>
                                                                    <svg width="15px" height="10px" className="checkmark">
                                                                        <polyline points="1,5 6,9 14,1" />
                                                                    </svg>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>}

                                                </div>
                                            </TabPanel>*/
                                        }
                                    </div>
                                </motion.div>
                            )
                    )
            }
        </>
    )
}

export default Info;