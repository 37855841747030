import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "NavigationApp": "Navigation application",
            "ShowFinishedDeliveries": "Show finished deliveries",
            "ShowFinishedBufferSlots": "Show finished bufferslots",
            "ShowFinishedUnproductiveTimes": "Show finished time-bound items",
            "Finished": "Finished",
            "NoRoutesAvailable": "No routes available",
            "General": "General",
            "Signature": "Signature",
            "Pictures": "Pictures",
            "InternalPictures": "Pictures (only internal)",
            "NoSignatureAvailable": "No signature available",
            "NoPicturesAvailable": "No pictures available",
            "Reset": "Reset",
            "Remark": "Remark",
            "Remarks": "Remarks",
            "Complete": "Complete",
            "Completing": "Complete",
            "ReasonToSkip": "Reason to skip",
            "YouAreOnline": "You are online",
            "YouAreOffline": "You are offline",
            "SomethingUnexpectedHappend": "Something unexpected happend",
            "TryAgain": "Please try again.",
            "GeneralErrorMessage": "If this problem persists, please contact your support.",
            "Route": "Route",
            "Routes": "Routes",
            "AllDeliveriesAreFinished": "All orders have been completed",
            "RoutesChangeInSettings": "If you want to consult the route you can change it in settings.",
            "Start": "Start",
            "Cancel": "Cancel",
            "Stop": "Stop",
            "Info": "Info",
            "Navigation": "Navigate",
            "Finish": "Finish",
            "Done": "Finished",
            "Skip": "Skip",
            "Interrupt": "Interrupt",
            "Interrupted": "Interrupted",
            "Resume": "Resume",
            "StartTimeRegistrationQuestion": "Are you sure you want to start a time registration for ",
            "ResumeTimeregistrationQuestion": "Are you sure you want to resume a time registration for ",
            "NoDriverRemarkAvailable": "No driver remark available",
            "NoRemarkAvailable": "No remark available",
            "NoTimewindowAvailable": "No timewindow available",
            "ChoosePicture": "Choose picture",
            "Pause": "Pause",
            "ClickButtonToResumeActivities": "Click the button to resume your activities.",
            "RoutesHaveBeenChanged": "Your routes have been changed, click the button at the top to reload.",
            "Reload": "Reload",
            "Settings": "Settings",
            "Checklist": "Checklist",
            "Files": "Files",
            "NoFilesAvailable": "No files available",
            "NoChecklistAvailable": "No checklist available",
            "Yes": "Yes",
            "No": "No",
            "StartPauseActivitiesQuestion": "Are you sure you want to pause your activities?",
            "StartPauseTypeSelection": "Please select which type of break you wanna start.",
            "DownloadFailed": "Download failed",
            "New": "New",
            "Ok": "Ok",
            "Filter": "Filter",
            "Filters": "Filters",
            "FilterValue": "Filter value",
            "Location": "Locatie",
            "ModernisationProject": "Modernisation project",
            "ModernisationProjects": "Modernisation projects",
            "SelectModernisationProject": "Select a modernisation project",
            "RequestTaskFromModernisationProjectConfirmation": "Are you sure you wanna request a task for the selected modernisation project?",
            "ConfirmRequest": "Confirm request",
            "Confirm": "Confirm",
            "Back": "Back",
            "RequestSuccessfullySent": "Your request has been sent successfully.",
            "RequestFailed": "An error occurred during your request.",
            "ElevatorNumber": "Elevator number",
            "RequestTaskConfirmation": "Are you sure you wanna request a task?",
            "AppPermissions": "App permissions",
            "AppPermission_geolocation": "Location",
            "AppPermission_notifications": "Notifications",
            "ActiveElevatorAtArrival": "Active elevator at arrival",
            "ActiveElevatorAtDeparture": "Active elevator at departure",
            "StartedOn": "Started on",
            "DeleteHistory": "Delete browsedata",
            "ClearBrowserDataUrlCopiedToClipboard": "URL has been successfully copied. Please paste it in your Chrome browser and click on \"Delete browsedata\".",
            "DataManagement": "Data management",
            "Import": "Import",
            "Delete": "Delete",
            "LocalData": "Local data",
            "LocalDataLossWarning": "Some of your local data has not been synced to our systems. It's possible to delete it, but be aware that this data will be lost forever.",
            "LocalDataRemoved": "Local data has been removed successfully.",
            "IAgree": "I agree",
            "MemoryUsage": "Memory usage",
            "SystemInfo": "System information",
            "OperatingSystem": "Operating system",
            "Image": "Image",
            "Of": "of",
            "ConfirmRouteInterruptRequest": "Are you sure you wanna interrupt this route?",
            "BrowserNotSupported": "Your browser isn't supported yet",
            "BrowserRecommendation": "It's possible that some of the features will not work as smooth as we want. Please use <b>{0}</b> instead to get the full experience.",
            "History": "History",
            "NoHistoryAvailable": "No historical data available",
            "ProjectType": "Project type",
            "Next": "Next",
            "Previous": "Previous",
            "TaskRequestErrorPermissionDenied": "Unable to send your request because the application has no permission to determine your current location.",
            "TaskRequestErrorLocationNotAvailable": "Unable to send your request because the location services of your device are not enabled.",
            "TaskRequestErrorTimeOut": "Unable to send your request because of a time-out during determination of your current location.",
            "TaskRequestErrorInformation": "We have some problems detecting your current location. Please wait some seconds, we'll give it another try.",
            "NoArticlesAvailable": "No articles available",
            "NoLocalArticlesAvailable": "No articles have been created yet.",
            "Quantity": "Quantity",
            "TimeWindow": "Timewindow",
            "ETA": "Estimated time of arrival",
            "ETF": "Estimated time of departure",
            "Address": "Address",
            "Articles": "Articles",
            "QuantityIn": "Picked",
            "QuantityOut": "Delivered",
            "In": "In",
            "Out": "Out",
            "Used": "Used",
            "OldPictures": "Old pictures",
            "ValidationErrorsOccured": "Validation errors occured.",
            "Name": "Name",
            "Description": "Description",
            "Brand": "Brand",
            "BrandCode": "Code (Brand)",
            "BrandDescription": "Description (Brand)",
            "Unit": "Unit",
            "Returnables": "Returnables",
            "Search": "Search",
            "SupplierArticleCode": "Article code EP",
            "SendLogs": "Send support request",
            "ExportLogs": "Export logs",
            "SupportMailBody": "Oops... it looks like you're in trouble with our application.\nPlease provide us with the GUID below.\nThat way we can help you much more precisely.\n\nThanks!",
            "SupportRequestSent": "You're support-request has been sent.",
            "NoFeedbackAvailable": "No feedback available.",
            "SuggestedBreak": "Suggested break",
            "WrongDisplayMode": "It looks like you're using this app inside your browser. Please close all of the tabs and reopen the app as an installed application on your phone.",
            "UnsupportedFileFormatSelection": "One or more of the selected files are in an unsupported file format.",
            "UpdateStatus": "Update the status",
            "UpdateStatusContent": "Below is a list of options. Please select the new status you wish to assign.",
            "SelectAValue": "Select a value",
            "NoMaintenanceProjectFound": "No maintenance project could be found.",
            "DisplaySettings": "Display settings",
            "BufferSlot": "Bufferslot",
            "OtherRouteInProgress": "Another route is still in progress.",
            "Total": "Total",
            "StockItems": "Stockitems",
            "SelectionPlaceholder": "Please select a value"
        }
    },
    nl: {
        translation: {
            "NavigationApp": "Navigatie applicatie",
            "ShowFinishedDeliveries": "Toon afgewerkte leveringen",
            "ShowFinishedBufferSlots": "Toon afgewerkte bufferslots",
            "ShowFinishedUnproductiveTimes": "Toon afgewerkte tijdsgebonden items",
            "Finished": "Afgelopen",
            "NoRoutesAvailable": "Geen routes beschikbaar",
            "General": "Algemeen",
            "Signature": "Handtekening",
            "Pictures": "Foto's",
            "InternalPictures": "Foto's (enkel intern)",
            "NoSignatureAvailable": "Geen handtekening beschikbaar",
            "NoPicturesAvailable": "Geen foto's beschikbaar",
            "Reset": "Opnieuw",
            "Remark": "Opmerking",
            "Remarks": "Opmerkingen",
            "Complete": "Voltooi",
            "Completing": "Voltooien",
            "ReasonToSkip": "Reden van overslaan",
            "YouAreOnline": "Je bent online",
            "YouAreOffline": "Je bent offline",
            "SomethingUnexpectedHappend": "Er is iets onverwachts gebeurd",
            "TryAgain": "Gelieve opnieuw te proberen.",
            "GeneralErrorMessage": "Indien dit probleem zich blijft voordoen, gelieve uw support te contacteren.",
            "Route": "Route",
            "Routes": "Routes",
            "AllDeliveriesAreFinished": "Alle opdrachten zijn afgewerkt.",
            "RoutesChangeInSettings": "Indien u de route nog wilt raadplegen, kan u dit wijzigen in instellingen.",
            "Start": "Starten",
            "Cancel": "Annuleer",
            "Stop": "Stoppen",
            "Info": "Informatie",
            "Navigation": "Navigatie",
            "Interrupt": "Onderbreken",
            "Interrupted": "Onderbroken",
            "Finish": "Afwerken",
            "Done": "Afwerken",
            "Skip": "Overslaan",
            "Resume": "Hervatten",
            "StartTimeRegistrationQuestion": "Bent u zeker dat u de tijdsregistratie wilt starten voor ",
            "ResumeTimeregistrationQuestion": "Bent u zeker dat u de tijdsregistratie wilt hervatten voor ",
            "NoDriverRemarkAvailable": "Geen opmerking van de bestuurder beschikbaar",
            "NoRemarkAvailable": "Geen opmerking beschikbaar",
            "NoTimewindowAvailable": "Geen tijdsvenster beschikbaar",
            "ChoosePicture": "Kies foto",
            "Pause": "Pauze",
            "ClickButtonToResumeActivities": "Klik op de knop om je activiteiten te hervatten.",
            "RoutesHaveBeenChanged": "Uw routes zijn aangepast, klik op de knop bovenaan om te herladen.",
            "Reload": "Herladen",
            "Settings": "Instellingen",
            "Checklist": "Checklist",
            "Files": "Bijlagen",
            "NoFilesAvailable": "Geen bijlagen beschikbaar",
            "NoChecklistAvailable": "Geen checklist beschikbaar",
            "Yes": "Ja",
            "No": "Nee",
            "StartPauseActivitiesQuestion": "Bent u zeker dat u uw activiteiten wenst te pauzeren?",
            "StartPauseTypeSelection": "Gelieve de pauzesoort te selecteren die u wenst te starten.",
            "DownloadFailed": "Download mislukt",
            "New": "Nieuw",
            "Ok": "Ok",
            "Filter": "Filteren",
            "Filters": "Filters",
            "FilterValue": "Filterwaarde",
            "Location": "Locatie",
            "ModernisationProject": "Modernisatieproject",
            "ModernisationProjects": "Modernisatieprojecten",
            "SelectModernisationProject": "Modernisatieproject selecteren",
            "RequestTaskFromModernisationProjectConfirmation": "Weet u zeker dat u een opdracht wenst aan te vragen voor het geselecteerde modernisatieproject?",
            "ConfirmRequest": "Aanvraag bevestigen",
            "Confirm": "Bevestigen",
            "Back": "Terug",
            "RequestSuccessfullySent": "Uw verzoek is succesvol verzonden.",
            "RequestFailed": "Er is een fout opgetreden bij het verzenden van uw verzoek.",
            "ElevatorNumber": "Liftnummer",
            "RequestTaskConfirmation": "Weet u zeker dat u een opdracht wenst aan te vragen?",
            "AppPermissions": "App-machtigingen",
            "AppPermission_geolocation": "Locatie",
            "AppPermission_notifications": "Meldingen",
            "ActiveElevatorAtArrival": "Lift in dienst bij aankomst",
            "ActiveElevatorAtDeparture": "Lift in dienst bij vertrek",
            "StartedOn": "Gestart op",
            "DeleteHistory": "Browsegegevens wissen",
            "ClearBrowserDataUrlCopiedToClipboard": "De URL werd succesvol gekopieerd. Gelieve deze te plakken in uw Chrome browser en te klikken op \"Browsegegevens wissen\".",
            "DataManagement": "Gegevensbeheer",
            "Import": "Importeren",
            "Delete": "Verwijderen",
            "LocalData": "Lokale gegevens",
            "LocalDataLossWarning": "Enkele lokale gegevens werden nog niet gesynchroniseerd met onze systemen. Het is mogelijk om deze te verwijderen maar deze gegevens zullen dan definitief verloren gaan.",
            "LocalDataRemoved": "Lokale gegevens zijn succesvol verwijderd.",
            "IAgree": "Ik ga akkoord",
            "MemoryUsage": "Geheugengebruik",
            "SystemInfo": "Systeeminformatie",
            "OperatingSystem": "Besturingssysteem",
            "ImageCompressProgress": "Foto {0} van {1}",
            "Image": "Afbeelding",
            "Of": "van",
            "ConfirmRouteInterruptRequest": "Weet u zeker dat u deze route wenst te onderbreken?",
            "BrowserNotSupported": "Uw browser wordt niet ondersteund",
            "BrowserRecommendation": "Mogelijks werken sommige functies niet zoals we willen. Gelieve <b>{0}</b> te gebruiken voor een optimale gebruikerservaring.",
            "History": "Geschiedenis",
            "NoHistoryAvailable": "Geen historische gegevens beschikbaar",
            "ProjectType": "Projectsoort",
            "Next": "Volgende",
            "Previous": "Vorige",
            "TaskRequestErrorPermissionDenied": "Het is onmogelijk om uw verzoek te verzenden aangezien de applicatie geen rechten heeft om uw locatie op te vragen.",
            "TaskRequestErrorLocationNotAvailable": "Het is onmogelijk om uw verzoek te verzenden aangezien de locatiebepaling van uw toestel niet is ingeschakeld.",
            "TaskRequestErrorTimeOut": "Het is onmogelijk om uw verzoek te verzenden aangezien er een time-out is opgetreden bij het bepalen van uw locatie.",
            "TaskRequestErrorInformation": "We hebben wat problemen met het detecteren van je huidige locatie. Wacht een paar seconden, we proberen het nog een keer.",
            "NoArticlesAvailable": "Geen artikels beschikbaar",
            "NoLocalArticlesAvailable": "Er werden nog geen artikelen aangemaakt.",
            "Quantity": "Aantal",
            "TimeWindow": "Tijdsvenster",
            "ETA": "Verwachte aankomsttijd",
            "ETF": "Verwachte vertrektijd",
            "Address": "Adres",
            "Articles": "Artikels",
            "QuantityIn": "Opgehaald",
            "QuantityOut": "Geleverd",
            "In": "In",
            "Out": "Uit",
            "Used": "Verbruikt",
            "OldPictures": "Oude afbeeldingen",
            "ValidationErrorsOccured": "Er zijn validatiefouten opgetreden.",
            "Name": "Naam",
            "Description": "Omschrijving",
            "Brand": "Merk",
            "BrandCode": "Code (Merk)",
            "BrandDescription": "Omschrijving (Merk)",
            "Unit": "Eenheid",
            "Returnables": "Leeggoed",
            "Search": "Zoeken",
            "SupplierArticleCode": "Artikelcode EP",
            "SendLogs": "Verstuur ondersteuningsverzoek",
            "ExportLogs": "Exporteer logs",
            "SupportMailBody": "Oops... het lijkt alsof je problemen hebt met onze applicatie.\nGelieve ons de onderstaande GUID te bezorgen.\nDaardoor kunnen we je preciezer helpen.\n\nBedankt!",
            "SupportRequestSent": "Uw verzoek werd verzonden.",
            "NoFeedbackAvailable": "Geen feedback beschikbaar.",
            "SuggestedBreak": "Voorgestelde pauze",
            "WrongDisplayMode": "Het lijkt erop dat u deze app in uw browser gebruikt. Gelieve alle openstaande tabs te sluiten en de app als een geïnstalleerde applicatie op uw mobiel apparaat te openen.",
            "UnsupportedFileFormatSelection": "Een of meerdere van de geselecteerde bestanden hebben een bestandsindeling die niet ondersteund wordt.",
            "UpdateStatus": "Status bijwerken",
            "UpdateStatusContent": "Hieronder vindt u een lijst met keuzemogelijkheden. Gelieve de nieuwe status te selecteren die u wenst toe te wijzen.",
            "SelectAValue": "Selecteer een waarde",
            "NoMaintenanceProjectFound": "Er kon geen onderhoudsproject gevonden worden.",
            "DisplaySettings": "Weergave instellingen",
            "BufferSlot": "Bufferslot",
            "OtherRouteInProgress": "Er is nog een andere route in uitvoering.",
            "Total": "Totaal",
            "StockItems": "Voorraadartikels",
            "SelectionPlaceholder": "Gelieve een waarde te selecteren"
        }
    },
    fr: {
        translation: {
            "NavigationApp": "Application de navigation",
            "ShowFinishedDeliveries": "Afficher les livraisons terminées",
            "ShowFinishedBufferSlots": "Show finished bufferslots",
            "ShowFinishedUnproductiveTimes": "Show finished time-bound items",
            "Finished": "Passé",
            "NoRoutesAvailable": "Aucun routes disponible",
            "General": "Général",
            "Signature": "Signature",
            "Pictures": "Photos",
            "InternalPictures": "Photos (only internal)",
            "NoSignatureAvailable": "Aucune signature disponible",
            "NoPicturesAvailable": "Aucune photo disponible",
            "Reset": "Réinitialiser",
            "Remark": "Remarque",
            "Remarks": "Remarques",
            "Complete": "Compléter",
            "Completing": "Achèvement",
            "ReasonToSkip": "Raison du saut",
            "YouAreOnline": "Tu es en ligne",
            "YouAreOffline": "Tu es hors ligne",
            "SomethingUnexpectedHappend": "Quelque chose d'inattendu s'est produit",
            "TryAgain": "Veuillez réessayer.",
            "GeneralErrorMessage": "Si ce problème persiste, veuillez contacter votre support.",
            "Route": "Route",
            "Routes": "Routes",
            "AllDeliveriesAreFinished": "Toutes les commandes ont été complétées",
            "RoutesChangeInSettings": "Si vous souhaitez toujours consulter l'itinéraire, vous pouvez le modifier dans les paramètres.",
            "Start": "Commencer",
            "Cancel": "Annuler",
            "Stop": "Arrêter",
            "Info": "Information",
            "Navigation": "Navigation",
            "Interrupt": "Interrompre",
            "Interrupted": "Interrompu",
            "Finish": "Finir",
            "Done": "Finir",
            "Skip": "Passer",
            "Resume": "Pour reprendre",
            "StartTimeRegistrationQuestion": "Êtes-vous sûr de vouloir démarrer l'enregistrement du temps pour ",
            "ResumeTimeregistrationQuestion": "Êtes-vous sûr de vouloir reprendre l'enregistrement des temps pour ",
            "NoDriverRemarkAvailable": "Aucun commentaire de pilote disponible",
            "NoRemarkAvailable": "Aucun commentaire disponible",
            "NoTimewindowAvailable": "Aucune fenêtre horaire disponible",
            "ChoosePicture": "Choisissez une photo",
            "Pause": "Pause",
            "ClickButtonToResumeActivities": "Cliquez sur le bouton pour reprendre vos activités.",
            "RoutesHaveBeenChanged": "Vos parcours ont été modifiés, cliquez sur le bouton en haut pour recharger.",
            "Reload": "Recharger",
            "Settings": "Paramètres",
            "Checklist": "Liste de contrôle",
            "Files": "Pièces jointes",
            "NoFilesAvailable": "Aucune pièce jointe disponible",
            "NoChecklistAvailable": "Aucune liste de contrôle disponible",
            "Yes": "Oui",
            "No": "Non",
            "StartPauseActivitiesQuestion": "Voulez-vous vraiment mettre vos activités en pause ?",
            "StartPauseTypeSelection": "Veuillez sélectionner le type de pause que vous souhaitez démarrer.",
            "DownloadFailed": "Échec du téléchargement",
            "New": "Nouveau",
            "Ok": "d'Accord",
            "Filter": "Filtre",
            "Filters": "Filtres",
            "FilterValue": "Valeur du filtre",
            "Location": "Lieu",
            "ModernisationProject": "Projet de modernisation",
            "ModernisationProjects": "Projets de modernisation",
            "SelectModernisationProject": "Sélectionnez un projet de modernisation",
            "RequestTaskFromModernisationProjectConfirmation": "Êtes-vous sûr de vouloir demander une mission pour le projet de modernisation sélectionné ?",
            "ConfirmRequest": "Confirmer la requête",
            "Confirm": "Confirmer",
            "Back": "Précédent",
            "RequestSuccessfullySent": "Votre demande a été envoyée avec succès.",
            "RequestFailed": "Une erreur s'est produite lors de l'envoi de votre demande.",
            "ElevatorNumber": "Numéro d'ascenseur",
            "RequestTaskConfirmation": "Êtes-vous sûr de vouloir demander un devoir?",
            "AppPermissions": "Autorisations d'application",
            "AppPermission_geolocation": "Lieu",
            "AppPermission_notifications": "Notifications",
            "ActiveElevatorAtArrival": "Ascenseur en service à l'arrivée",
            "ActiveElevatorAtDeparture": "Ascenseur en service au départ",
            "StartedOn": "Démarrage le",
            "DeleteHistory": "Effacer les données de navigation",
            "ClearBrowserDataUrlCopiedToClipboard": "L'URL a été copiée avec succès. Veuillez le coller dans votre navigateur Chrome et cliquer sur \"Effacer les données de navigation\".",
            "DataManagement": "Gestion de données",
            "Import": "Importer",
            "Delete": "Supprimer",
            "LocalData": "Données locales",
            "LocalDataLossWarning": "Certaines données locales n'ont pas encore été synchronisées avec nos systèmes. Il est possible de le supprimer, mais ces données seront alors définitivement perdues.",
            "LocalDataRemoved": "Les données locales ont été supprimées avec succès.",
            "IAgree": "Je suis d'accord",
            "MemoryUsage": "Utilisation de la mémoire",
            "SystemInfo": "Informations système",
            "OperatingSystem": "Système opérateur",
            "ImageCompressProgress": "Photo {0} de {1}",
            "Image": "Image",
            "Of": "de",
            "ConfirmRouteInterruptRequest": "Voulez-vous vraiment interrompre cet route?",
            "BrowserNotSupported": "Ton navigateur n'est pas supporté",
            "BrowserRecommendation": "Certaines fonctions peuvent ne pas fonctionner comme nous le souhaitons. Veuillez utiliser <b>{0}</b> pour une expérience utilisateur optimale.",
            "History": "L'histoire",
            "NoHistoryAvailable": "Aucune donnée historique disponible",
            "ProjectType": "Type de projet",
            "Next": "Suivante",
            "Previous": "Précédent",
            "TaskRequestErrorPermissionDenied": "Il est impossible d'envoyer votre demande car l'application n'a pas le droit de demander votre position.",
            "TaskRequestErrorLocationNotAvailable": "Il est impossible d'envoyer votre demande car le suivi de localisation de votre appareil n'est pas activé.",
            "TaskRequestErrorTimeOut": "Il est impossible d'envoyer votre demande car la détermination de votre position a expiré.",
            "TaskRequestErrorInformation": "Nous rencontrons des difficultés pour détecter votre position actuelle. Attendez quelques secondes, nous réessayerons.",
            "NoArticlesAvailable": "Aucun article disponible",
            "NoLocalArticlesAvailable": "Aucun article n'a encore été créé.",
            "Quantity": "Nombre de",
            "TimeWindow": "Créneau horaire",
            "ETA": "Heure d'arrivée estimée",
            "ETF": "Heure de départ prévue",
            "Address": "Adresse",
            "Articles": "Des articles",
            "QuantityIn": "Récupéré",
            "QuantityOut": "Livré",
            "In": "In",
            "Out": "Extradé",
            "Used": "Utilisé",
            "OldPictures": "Images anciennes",
            "ValidationErrorsOccured": "Des erreurs de validation se sont produites.",
            "Name": "Nom",
            "Description": "Description",
            "Brand": "Marque",
            "BrandCode": "Code (Marque)",
            "BrandDescription": "Description (Marque)",
            "Unit": "Unité",
            "Returnables": "Les vides",
            "Search": "Chercher",
            "SupplierArticleCode": "Code de l'article EP",
            "SendLogs": "Envoyer une demande d'assistance",
            "ExportLogs": "Exporter les journaux",
            "SupportMailBody": "Oops... il semble que vous rencontriez des problèmes avec notre application.\nVeuillez nous fournir le GUID ci-dessous.\nNous pourrons ainsi vous aider plus précisément.\n\nMerci !",
            "SupportRequestSent": "Votre demande à été envoyé.",
            "NoFeedbackAvailable": "Aucun commentaire disponible.",
            "SuggestedBreak": "Pause suggérée",
            "WrongDisplayMode": "Il semble que vous utilisiez cette application dans votre navigateur. Veuillez fermer tous les onglets ouverts et ouvrir l'application en tant qu'application installée sur votre appareil mobile.",
            "UnsupportedFileFormatSelection": "One or more of the selected files are in an unsupported file format.",
            "UpdateStatus": "Mettre à jour le statut",
            "UpdateStatusContent": "Vous trouverez ci-dessous une liste d'options. Veuillez sélectionner le nouveau statut que vous souhaitez attribuer.",
            "SelectAValue": "Select a value",
            "NoMaintenanceProjectFound": "No maintenance project could be found.",
            "DisplaySettings": "Display settings",
            "BufferSlot": "Bufferslot",
            "OtherRouteInProgress": "Il y a un autre itinéraire en cours.",
            "Total": "Total",
            "StockItems": "Article en stock",
            "SelectionPlaceholder": "Veuillez sélectionner une option"
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'nl',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection:
        {
            // order and from where user language should be detected
            order: ['localStorage'],

            // keys or params to lookup language from
            lookupLocalStorage: 'i18nextLng'
        }
    });

export default i18n;
export const SupportedLanguagesByDriverApp = Object.keys(resources);