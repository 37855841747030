import React, { useEffect, useState, useRef, useCallback, useReducer } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../utils/contexts/appContext';
import db from '../../../utils/dexie/driverappdb';
import queries from '../../../utils/dexie/queries';
import SignaturePad from 'react-signature-pad-wrapper';

import { makeStyles, hexToRgb } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { motion } from "framer-motion";

import './Arrive.css';
import Remarks from '../../../components/remarks';
import { Typography, Box, AppBar, Tabs, Tab, Fade, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArticlesManager from '../job/articlesManager';
import { usePlanItemStatusManagement } from '../../../hooks/plan-item-status-management';
import { PlanningItemType } from '../../../utils/enums/planning-item-type';
import { PlanningItemStatus } from '../../../utils/enums/planning-item-status';
import { useSinglePress } from '../../../hooks/single-press';
import Loader from '../../../components/loader';
import { useIsMounted } from '../../../hooks/is-mounted';

const pageVariants = {
    initial: {
        opacity: 0,
        x: "100vh"
    },
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0,
        x: "100vh"
    }
};

const pageTransition = {
    duration: 0.2
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    actionButton: {
        color: theme.palette.success.main,
        borderColor: hexToRgb(theme.palette.success.main, .5)
    },
    panel: {
        flexGrow: 1,
        backgroundColor: "#ffffff !important"
    },
    tabPanel: {
        paddingTop: 15
    },
    errorLabel: {
        color: "#FF0000 !important"
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Arrive() {
    let { dropId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const [, dispatch] = useAppContext();
    const [drop, setDrop] = useState({});
    const classes = useStyles();
    const [t] = useTranslation();
    const [appParams, setAppParams] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [showArticlesTab, setShowArticlesTab] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [useSignature, setUseSignature] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useIsMounted();

    const [showValidationSummary, setShowValidationSummary] = useState(false);
    const [validation, dispatchValidation] = useReducer((state, validationResult) => {
        switch(validationResult.key) {
            case 'signature':
                return {
                    ...state,
                    signature: validationResult.valid
                };
            default:
                return {
                    ...state
                };
        }
    }, {
        signature: true
    });

    const driverRemarks = useRef();
    const signaturePad = useRef();
    const articlesManager = useRef();

    // Currently using PlanItemStatusManagement to update the status of the planning item until we've rewritten this screen to use the new DropStatusManagement hook
    const updateRoutePlanningItemStatus = usePlanItemStatusManagement(drop?.routeId, PlanningItemType.DROP, parseInt(dropId));

    let isMobileLimit = 800;

    const handleFinish = useSinglePress(async (e) => {
        try {            
            e.preventDefault();

            if (validate()) {
                let now = new Date();
                var utc = now.getTime() - now.getTimezoneOffset() * 60000;

                let dropFinishAction = { type: "DropFinish", key: dropId, time: utc };
                await queries.isActionAllowed(dropFinishAction)
                    .then(async (allowed) => {
                        if (allowed) {
                            /*db.drops.get(parseInt(dropId)).then((drop) =>{
                                db.routes.update(parseInt(drop.routeId), { delay: utils.calculateDelay(new Date(drop.eta), now) });
                            });*/

                            saveData();

                            // lat / lon invullen
                            await new Promise((resolve, reject) => {
                                if (navigator.geolocation) {
                                    navigator.geolocation.getCurrentPosition(
                                        location => {
                                            db.dropdata.update(parseInt(dropId), {
                                                status: 40,
                                                lat: location.coords.latitude, 
                                                lon: location.coords.longitude
                                            });
                                            resolve();
                                        },
                                        error => {
                                            // No access allowed to location
                                            db.dropdata.update(parseInt(dropId), {
                                                status: 40,
                                                lat: 0,
                                                lon: 0
                                            });
                                            resolve();
                                        },
                                        { 
                                            enableHighAccuracy: true,
                                            timeout: 1000
                                        }
                                    )
                                } else {
                                    db.dropdata.update(parseInt(dropId), {
                                        status: 40,
                                        lat: 0,
                                        lon: 0
                                    });
                                    resolve();
                                }
                            }).then(async () => {
                                await updateRoutePlanningItemStatus(PlanningItemStatus.FINISHED);
                                // Make sure this is executed once the position is retrieved (if geolocation enabled)
                                await db.actions.put(dropFinishAction);
                                history.replace(`/routes/${drop.routeId}`);
                            });
                        }
                    });
            } else {
                setShowValidationSummary(true);
                setTimeout(() => setShowValidationSummary(false), 3000);
            }
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }        
    });

    const handleNavigateToInformation = () => {
        try {
            saveData()
                .finally(() => history.push(`/routes/timeline/info/${drop.id}`));
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const saveData = useCallback(() => {

        return new Promise((resolve, reject) => {
            try {
                if (useSignature && signaturePad != null && signaturePad.current != null) {
                    db.dropdata.update(parseInt(dropId), {
                        signature: signaturePad.current.isEmpty() ? null : signaturePad.current.toDataURL()
                    });
                }
        
                // Save the remarks
                if (driverRemarks != null && driverRemarks.current != null) {
                    driverRemarks.current.save();
                }
        
                // Save the articles first
                if (articlesManager != null && articlesManager.current != null) {
                    articlesManager.current.save();
                }

                resolve();
            }
            catch(error) {
                queries.addErrorToLogs(error);
                history.push('/error');
                reject(error);
            }
        });

        
    }, [dropId, useSignature, history]);

    const handleClear = () => {
        signaturePad.current.clear();
    }

    const handleOnBeginSignature = () => {
        let textAreas = document.getElementsByTagName("textarea");
        for (var i=0; i < textAreas.length; i++) {
            textAreas[i].blur();
        }
    }

    const validate = useCallback((silenceMode) => {
        let driverRemarksValid = true;
        if (driverRemarks != null && driverRemarks.current != null)
            driverRemarksValid = driverRemarks.current.validate(silenceMode);

        let signatureValid = (useSignature && appParams.signOnGlassRequired) ? 
            (signaturePad != null && signaturePad.current != null && !signaturePad.current.isEmpty()) : 
            true;

        // Dispatch the validation to update the UI
        dispatchValidation({ key: "signature", valid: signatureValid });

        return driverRemarksValid && signatureValid;
    }, [signaturePad, useSignature, appParams.signOnGlassRequired]);

    const handleTabChange = (event, newTabValue) => {
        saveData()
            .finally(() => setTabValue(newTabValue));
    };

    const loadData = useCallback(async () => {
        return await db.transaction('rw', 
            db.drops, db.dropdata, db.locations, db.driverremarks, db.params, db.dropdetailsdata2, 
            async () => {
                let matchingDrops = await db.drops.where({ id: parseInt(dropId) })
                    .with({ location: 'location', driverRemarks: 'driverremarks' });

                let dropsWithData = await queries.joinDropData(matchingDrops);
                if (dropsWithData.length > 0) {
                    let drop = dropsWithData[0];
                    let params = await db.params.where('id').notEqual(0).first();
                    let hasAnyDropDetailsData = await db.dropdetailsdata2.where({ dropId: parseInt(dropId) } ).count() > 0;

                    dispatch({ 
                        type: 'changeHeader', 
                        header: { title: `${t("Complete")} ${drop.location.name}`, child: true },
                        onNavigation: saveData
                    });

                    if (isMounted()) {
                        if (signaturePad != null && signaturePad.current && drop.signature != null) {
                            signaturePad.current.fromDataURL(drop.signature);
                        }
    
                        setDrop(drop);
                        setAppParams(params);
                        setShowArticlesTab(params.articleInventory || hasAnyDropDetailsData);
                        setUseSignature(params.alwaysSignOnGlass || drop.signonglass);
                    }
                }
            });
    }, [dispatch, dropId, saveData, t, signaturePad, isMounted]);

    useEffect(() => {
        setIsMobile(window.innerWidth <= isMobileLimit);

        if (location.state != null && location.state.selectedTab != null) {
            setTabValue(location.state.selectedTab);
            // Delete the state to prevent being stuck on the redirectionTab after rerenders.
            delete location.state.selectedTab;
        }

        loadData()
            .finally(() => setIsLoading(false));

    }, [isMobileLimit, location.state, loadData]);

    return (
        <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
            {
                isLoading ?
                    <Loader /> :
                    <>
                        <AppBar position="static" className={classes.panel} style={{ boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)" }}>
                            <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth"
                                indicatorColor="primary" textColor="primary">
                                {
                                    isMobile ?
                                        <Tab icon={<FontAwesomeIcon icon={["fas", "info-circle"]} size="lg" />} {...a11yProps(0)} style={{ minWidth: "20%" }} /> :
                                        <Tab label={t("General")} {...a11yProps(0)} style={{ minWidth: "20%" }} />
                                }
                                {
                                    showArticlesTab ? 
                                        (isMobile ?
                                            <Tab icon={<FontAwesomeIcon icon={["fas", "shopping-cart"]} size="lg" />} {...a11yProps(1)} style={{ minWidth: "20%" }} /> :
                                            <Tab label={t("Articles")} {...a11yProps(1)} style={{ minWidth: "20%" }} />) :
                                        []
                                }
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <div className="global-container">
                                <div className={classes.root}>
                                    <Remarks ref={driverRemarks} remarks={drop.driverRemarks} />
                                    <div>
                                        {
                                            useSignature ?
                                                <div style={{ paddingTop: 15 }}>
                                                    <div className={`form-label ${validation.signature ? '' : classes.errorLabel}`}>
                                                        {t("Signature")} {appParams.signOnGlassRequired ? '*' : ''}
                                                    </div>
                                                    <Card>
                                                        <CardContent>
                                                            <SignaturePad redrawOnResize={true}
                                                                options={{ 
                                                                    onBegin: handleOnBeginSignature
                                                                }} 
                                                                ref={signaturePad} />
                                                        </CardContent>
                                                        <CardActions>
                                                            <Button onClick={handleClear} size="small" color="primary">
                                                                {t("Reset")}
                                                            </Button>
                                                        </CardActions>
                                                    </Card>
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="arrive-buttons">
                                <div className="arrive-buttons-left">
                                    <Button onClick={handleNavigateToInformation} fullWidth variant="outlined" color="primary">
                                        <FontAwesomeIcon icon={["fad", "info"]} />
                                        <span className="button-text-with-icon">{t("Info")}</span>
                                    </Button>
                                </div>
                                <div className="arrive-buttons-right">
                                    <Button onClick={handleFinish} fullWidth variant="outlined" className={classes.actionButton}>
                                        <FontAwesomeIcon icon={["fad", "check"]} />
                                        <span className="button-text-with-icon">{t("Finish")}</span>
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                            <ArticlesManager ref={articlesManager} dropId={parseInt(dropId)} 
                                catalogState={{ 
                                    redirectionUrl: `/routes/timeline/arrive/${dropId}`,
                                    redirectionTab: tabValue 
                                }} />
                        </TabPanel>
                        <Fade in={showValidationSummary}>
                            <Box textAlign="center">
                                <Chip label={t("ValidationErrorsOccured")} style={{ borderColor: "#e74c3c", backgroundColor: "#e74c3c", color: "#FFF", marginTop: 15, marginBottom: 15 }} />
                            </Box>
                        </Fade>
                    </>
            }
        </motion.div >
    );
}

export default Arrive;