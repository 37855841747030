import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import db from "../../../../utils/dexie/driverappdb";
import QuantityChanger from "../../../../components/quantityChanger";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesomeDuoToneStyle, FontAwesomeDuoToneStyleDanger } from "../../../../fontawesome";
import { primaryColor } from "../../../../themes/litefleet";
import { lookUpTranslatedDescription } from "../../../../utils/article";

const useArticleViewStyles = makeStyles(theme => ({
    articleRoot: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginBottom: 15
    },
    articleHeader: {
        flexGrow: 1
    },
    articleManagerWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    totalWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    textCenter: {
        textAlign: 'center'
    },
    readOnlyItemWrapper: {
        display: 'flex',
        marginBottom: 15
    },
    readOnlyIconWrapper: {
        paddingRight: '15px !important'
    },
    readOnlyContentWrapper: {
        flexGrow: 1
    }
}));

const ArticleView = ({ children, readonly, article, actions }) => {
    const [, i18n] = useTranslation();
    const classes = useArticleViewStyles();

    return (
        <>
            {
                !readonly ?
                    <Card className={classes.articleRoot}>
                        <CardHeader title={article.name} subheader={lookUpTranslatedDescription(article, i18n.language)} action={actions} className={classes.articleHeader} />
                        <CardContent>
                            { children }
                        </CardContent>
                    </Card> :
                    <Box className={classes.readOnlyItemWrapper}>
                        <Box className={classes.readOnlyIconWrapper}>
                            <FontAwesomeIcon icon={["fas", "caret-right"]} size="lg" style={FontAwesomeDuoToneStyle} color={primaryColor.main} />
                        </Box>
                        <Box className={classes.readOnlyContentWrapper}>
                            <Box fontWeight={700}>
                                <Typography variant="inherit">{article.name}</Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary">{lookUpTranslatedDescription(article, i18n.language)}</Typography>
                            { children }
                        </Box>
                    </Box>
            }
        </>
    );

}

const ArticleManager = forwardRef(({
        readonly,
        article,
        articleInventoryNoLimits,
        onDelete = (dropDetailsDataId) => {}
    }, ref) => {
    const [t,] = useTranslation();
    const [labelOut, setLabelOut] = useState(t("QuantityOut"));
    const [deliveredQuantityIn, setDeliveredQuantityIn] = useState(article?.dropdetailsdata?.deliveredQuantityIn ?? 0);
    const [deliveredQuantityOut, setDeliveredQuantityOut] = useState(article?.dropdetailsdata?.deliveredQuantityOut ?? 0);

    useEffect(() => {
        if (!articleInventoryNoLimits) {
            setLabelOut(t("Used"));
        }
    }, [t, articleInventoryNoLimits]);

    useImperativeHandle(ref, () => ({

        getUpdatedValues: () => ({ 
            dropDetailsDataId: article.dropdetailsdata.id, 
            deliveredQuantityIn: deliveredQuantityIn,
            deliveredQuantityOut: deliveredQuantityOut 
        })

    }));

    return (
        <ArticleView readonly={readonly} article={article} actions={
            !article?.alwaysAttached ? 
                <IconButton onClick={() => onDelete(article.dropdetailsdata.id)}>
                    <FontAwesomeIcon icon={["fad", "trash-alt"]} style={FontAwesomeDuoToneStyleDanger} size="lg" />
                </IconButton> : <></>
        }>
            <Grid container>
                {
                    !readonly ? 
                        <>
                            {
                                article.displayIn ?
                                    <Grid item xs={6}>
                                        <QuantityChanger label={t("In")} value={deliveredQuantityIn} minValue={0} 
                                            onChange={(newDeliveredQuantityIn) => setDeliveredQuantityIn(newDeliveredQuantityIn)} />
                                    </Grid> : <></>
                            }
                            {
                                article.displayOut ?
                                    <Grid item xs={6}>
                                        <QuantityChanger label={labelOut} value={deliveredQuantityOut} minValue={0} 
                                            onChange={(newDeliveredQuantityOut) => setDeliveredQuantityOut(newDeliveredQuantityOut)} />
                                    </Grid> : <></>
                            }
                        </> : 
                        <Grid item xs={12}>
                            {
                                article.displayIn ?
                                    <Typography>{t("In")}: {deliveredQuantityIn ?? 0}</Typography> : <></>
                            }
                            {
                                article.displayOut ?
                                    <Typography>{labelOut}: {deliveredQuantityOut ?? 0}</Typography> : <></>
                            }
                        </Grid>
                }
            </Grid>
        </ArticleView>
    );

});

const ArticlesManagerView = forwardRef(({ dropId, catalogState, articles, localArticles, readonly, articleInventoryEnabled, articleInventoryNoLimits }, ref) => {
    
    const [t,] = useTranslation();
    const classes = useArticleViewStyles();
    const [labelOut,] = useState(t(articleInventoryNoLimits ? "QuantityOut" : "Used"));
    const [, setVisibleLocalArticles] = useState([]);
    const [, setVisibleArticles] = useState([]);
    const [combinedArticles, setCombinedArticles] = useState([]);
    const [combinedArticleRefs, setCombinedArticleRefs] = useState([]);

    useEffect(() => {
        setVisibleArticles(articles ?? []);
        setVisibleLocalArticles(localArticles ?? []);
        mergeArticleArrays(articles, articleInventoryEnabled ? localArticles : []);
    }, [articles, localArticles, articleInventoryEnabled]);

    const deleteLocalArticle = async (dropDetailsDataId) => {
        // Delete the item
        db.dropdetailsdata2.delete(dropDetailsDataId);

        // Clear the refs so they won't block a re-rendering of the view
        setCombinedArticleRefs([]);

        // Update the visible local articles
        setVisibleLocalArticles(currentlyVisibleLocalArticles => {
            let indexToRemove = currentlyVisibleLocalArticles.indexOf(currentlyVisibleLocalArticles.find(localArticle => localArticle.dropdetailsdata.id === dropDetailsDataId));
            currentlyVisibleLocalArticles.splice(indexToRemove, 1);
            // Make sure the combined list is also updated
            mergeArticleArrays(articles, currentlyVisibleLocalArticles);
            return currentlyVisibleLocalArticles;
        });
    };

    const saveData = () => {
        // Save the articles
        if (combinedArticleRefs != null && combinedArticleRefs.length > 0) {
            let updatedValues = [];
            combinedArticles.forEach((article, i) => {
                if (combinedArticleRefs[i] != null) {
                    updatedValues.push(combinedArticleRefs[i].getUpdatedValues());
                }
            });

            updatedValues.forEach(async (a) => {
                await db.dropdetailsdata2.update(a.dropDetailsDataId, {
                    deliveredQuantityIn: a.deliveredQuantityIn,
                    deliveredQuantityOut: a.deliveredQuantityOut
                });
            });
        }
    };

    const mergeArticleArrays = (...articleArrays) => {
        let sortedArticles = articleArrays.flat()
            .sort((a, b) => {
                let aCreatedLocally = a?.dropdetailsdata?.createdLocally ?? 0;
                let bCreatedLocally = b?.dropdetailsdata?.createdLocally ?? 0;
                let createdLocallySortResult = aCreatedLocally - bCreatedLocally;

                if (createdLocallySortResult !== 0) {
                    return createdLocallySortResult;
                } else {
                    if (a.seqNr == null)
                        return 1;
                    else if (b.seqNr == null)
                        return -1;
                    else
                        return a.seqNr - b.seqNr;              
                }
            });

        setCombinedArticles(sortedArticles);
    }

    useImperativeHandle(ref, () => ({
        save: () => {
            saveData();
        }
    }));
    
    return (
        <Grid container>
            {
                !readonly && articleInventoryEnabled ?
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <NavLink onClick={saveData} replace={true} to={{ 
                                pathname: `${dropId}/catalog`,
                                state: { 
                                    ...catalogState, 
                                    excludedArticleIds: !articleInventoryNoLimits ? 
                                        combinedArticles.map(visibleArticle => visibleArticle.id) : []
                                }
                            }}>
                            <IconButton color="primary">
                                <FontAwesomeIcon icon={["fad", "plus-circle"]} size="2x" />
                            </IconButton>
                        </NavLink>
                    </Grid> : <></>
            }
            <Grid item xs={12}>
                {
                    (!combinedArticles || combinedArticles.length === 0) ?
                        <Typography gutterBottom align='center'>
                            {t("NoArticlesAvailable")}
                        </Typography> :
                        <Grid container spacing={3}>
                            {
                                combinedArticles.map((article, i) => 
                                    <Grid item xs={12} md={6} key={article.id} className={classes.articleManagerWrapper}>
                                        <ArticleManager readonly={readonly} article={article} onDelete={deleteLocalArticle}
                                            articleInventoryNoLimits={articleInventoryNoLimits} ref={(ref) => {
                                            setCombinedArticleRefs(currentArticleRefs => {
                                                if (ref != null && currentArticleRefs != null)
                                                    currentArticleRefs[i] = ref;
                                                return currentArticleRefs;
                                            })
                                        }} />
                                    </Grid>
                                )
                            }
                            {
                                readonly ?
                                    <Grid item xs={12}>
                                        <Divider />
                                        <Box fontWeight={700} marginLeft={'25px'}>
                                            <Typography variant="inherit" >{t('Total')}</Typography>
                                            <Typography>{t("In")}: {combinedArticles.map(a => a?.dropdetailsdata?.deliveredQuantityIn ?? 0).reduce((prevValue, currentValue) => prevValue + currentValue, 0)}, {labelOut}: {combinedArticles.map(a => a?.dropdetailsdata?.deliveredQuantityOut ?? 0).reduce((prevValue, currentValue) => prevValue + currentValue, 0)}</Typography>  
                                        </Box>
                                    </Grid> : null
                            }
                        </Grid>
                }
            </Grid>
        </Grid>
    );

});

export default ArticlesManagerView;