import i18n from "../../i18n";

function lookUpFromTranslations(item) {
     let result = item.description;

     if (item.translations != null && item.translations.length > 0) {
        const translation = item.translations.find(x => x.language?.abbreviation?.toLowerCase() === i18n.language.toLowerCase());
        if (translation)
            result = translation.translatedTerm;
    }

    return result;
}

export const lookUpTranslatedFeedbackLabel = (feedbackLabel) => lookUpFromTranslations(feedbackLabel);

export const lookUpTranslatedFeedbackOption = (feedbackOption) => lookUpFromTranslations(feedbackOption);