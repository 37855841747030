import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../utils/contexts/appContext';
import db from '../../../utils/dexie/driverappdb';
import queries from '../../../utils/dexie/queries';
import utils from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from "framer-motion";
import './Skip.css';
import Remarks from '../../../components/remarks';
import { AppBar, Tabs, Tab, Typography, Box, Fade, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArticlesManager from '../job/articlesManager';
import { usePlanItemStatusManagement } from '../../../hooks/plan-item-status-management';
import { PlanningItemType } from '../../../utils/enums/planning-item-type';
import { PlanningItemStatus } from '../../../utils/enums/planning-item-status';
import { useSinglePress } from '../../../hooks/single-press';
import { useCallback } from 'react';
import { useIsMounted } from '../../../hooks/is-mounted';
import Loader from '../../../components/loader';

const pageVariants = {
    initial: {
        opacity: 0,
        x: "100vh"
    },
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0,
        x: "100vh"
    }
};

const pageTransition = {
    duration: 0.2
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    panel: {
        flexGrow: 1,
        backgroundColor: "#ffffff !important"
    },
    tabPanel: {
        paddingTop: 15
    }
}));

function Skip() {
    let { dropId } = useParams();
    const location = useLocation();
    const history = useHistory();
    const [t] = useTranslation();
    const [, dispatch] = useAppContext();
    const [route, setRoute] = useState({});
    const [drop, setDrop] = useState({});
    const classes = useStyles();
    const [appParams, setAppParams] = useState([]);
    const [value, setValue] = useState(0);
    const [showArticlesTab, setShowArticlesTab] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showValidationSummary, setShowValidationSummary] = useState(false);

    const remarks = useRef();
    const articlesManager = useRef();

    const isMounted = useIsMounted();

    // Currently using PlanItemStatusManagement to update the status of the planning item until we've rewritten this screen to use the new DropStatusManagement hook
    const updateRoutePlanningItemStatus = usePlanItemStatusManagement(drop?.routeId, PlanningItemType.DROP, parseInt(dropId));

    let isMobileLimit = 800;

    const saveData = () => {
        // Save the remarks first
        if (remarks != null && remarks.current != null) {
            remarks.current.save();
        }

        // Save the articles first
        if (articlesManager != null && articlesManager.current != null) {
            articlesManager.current.save();
        }
    }

    const loadData = useCallback(async () => {
        return await db.transaction('rw', 
            db.drops, db.dropdata, db.locations, db.projecttypes, db.params, db.routes, 
            db.skipremarks, db.dropdetailsdata2, 
            async () => {
                let matchingDrops = await db.drops.where({ id: parseInt(dropId) })
                    .with({ location: 'location', skipRemarks: 'skipremarks' });

                let dropsWithData = await queries.joinDropData(matchingDrops);
                if (dropsWithData.length > 0){
                    let drop = dropsWithData[0];
                    let params = await db.params.where('id').notEqual(0).first();
                    let hasAnyDropDetailsData = await db.dropdetailsdata2.where({ dropId: parseInt(dropId) } ).count() > 0;
    
                    let routes = await db.routes
                        .where({ id: parseInt(drop.routeId) })
                        .with({ startLocation: 'startLocation', stopLocation: 'stopLocation' });

                    dispatch({ 
                        type: 'changeHeader', 
                        header: { 
                            title: `${t("Skip")} ${drop.location.name}`, 
                            child: true, 
                            onNavigation: saveData
                        }
                    });

                    if (isMounted()) {
                        setDrop(drop);
                        setRoute(routes[0]);
                        setAppParams(params);
                        setShowArticlesTab(params.articleInventory || hasAnyDropDetailsData);
                    }
                }
            });
    }, [dispatch, dropId, t, isMounted]);

    useEffect(() => {
        setIsMobile(window.innerWidth <= isMobileLimit);

        if (location.state != null && location.state.selectedTab != null) {
            setValue(location.state.selectedTab);
            // Delete the state to prevent being stuck on the redirectionTab after rerenders.
            delete location.state.selectedTab;
        }

        loadData()
            .finally(() => setIsLoading(false));
    }, [dispatch, dropId, t, isMobileLimit, location, loadData]);

    const validate = (silenceMode) => {
        if (remarks != null && remarks.current != null) 
            return remarks.current.validate(silenceMode);
        return true;
    };

    const handleSkip = useSinglePress(async () => {
        try {
            let now = new Date();
            var utc = now.getTime() - now.getTimezoneOffset() * 60000;

            let dropSkipAction = { type: "DropSkip", key: parseInt(dropId), time: utc };
            await queries.isActionAllowed(dropSkipAction)
                .then(async (allowed) => {
                    if (allowed) {
                        if (validate()) {
                            if (appParams.routeAutoStart && (route.status < 30 || route.status === 70) && drop.status < 30) {
                                await handleAutoStartRoute();
                            }
    
                            /*db.drops.get(parseInt(dropId)).then((drop) => {
                                db.routes.update(parseInt(drop.routeId), { delay: utils.calculateDelay(new Date(drop.eta), now) });
                            });*/
    
                            saveData();
    
                            // lat / lon invullen
                            await db.dropdata.update(parseInt(dropId), {
                                status: 50, 
                                lat: 0, 
                                lon: 0 
                            })
                            .then(async () => {
                                await updateRoutePlanningItemStatus(PlanningItemStatus.FINISHED);
                                await db.actions.put(dropSkipAction);
                            })
                            .finally(() => history.replace(`/routes/${drop.routeId}`));
                        }
                        else {
                            setShowValidationSummary(true);
                            setTimeout(() => setShowValidationSummary(false), 3000);
                        }
                    }
                });
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    });

    const handleAutoStartRoute = async () => {
        try {
            let newStatus = 30; // Busy
            let routeId = drop.routeId;
            let now = new Date();
            var utc = now.getTime() - now.getTimezoneOffset() * 60000;

            let routeStartAction = { type: "RouteStart", key: routeId, time: utc };
            await queries.isActionAllowed(routeStartAction)
                .then(allowed => {
                    if (allowed) {
                        db.routes.get(parseInt(routeId))
                            .then((route) => {
                                db.routes.update(parseInt(routeId), { 
                                    status: newStatus, 
                                    delay: utils.calculateDelay(new Date(route.start), now),
                                    realStart: new Date(now).toISOString()
                                });
                            });
                        db.actions.put(routeStartAction);
                    }
                });
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const handleNavigateToInfo = () => {
        try {
            saveData();
            history.push(`/routes/timeline/info/${drop.id}`);
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const handleTabChange = (event, newValue) => {
        saveData();
        setValue(newValue);
    };

    return (
        <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
            {
                isLoading ?
                    <Loader /> :
                    <>
                        <AppBar position="static" className={classes.panel} style={{ boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)" }}>
                            <Tabs value={value} onChange={handleTabChange} variant="fullWidth"
                                    indicatorColor="primary" textColor="primary">
                                {
                                    isMobile ?
                                        <Tab icon={<FontAwesomeIcon icon={["fas", "info-circle"]} size="lg" />} {...a11yProps(0)} style={{ minWidth: "20%" }} /> :
                                        <Tab label={t("General")} {...a11yProps(0)} style={{ minWidth: "20%" }} />
                                }
                                {
                                    showArticlesTab ? 
                                        (isMobile ?
                                            <Tab icon={<FontAwesomeIcon icon={["fas", "shopping-cart"]} size="lg" />} {...a11yProps(1)} style={{ minWidth: "20%" }} /> :
                                            <Tab label={t("Articles")} {...a11yProps(1)} style={{ minWidth: "20%" }} />) :
                                        []
                                }
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <div className="global-container">
                                <div className={classes.root}>
                                    <Remarks ref={remarks} remarkType="skip" remarks={drop.skipRemarks} />
                                </div>
                            </div>
                            <div className="skip-buttons">
                                <div className="skip-buttons-left">
                                    <Button onClick={handleNavigateToInfo} fullWidth variant="outlined" color="primary">
                                        <FontAwesomeIcon icon={["fad", "info"]} />
                                        <span className="button-text-with-icon">{t("Info")}</span>
                                    </Button>
                                </div>
                                <div className="skip-buttons-right">
                                    <Button onClick={handleSkip} fullWidth variant="outlined" style={{ borderColor: "#e74c3c", color: "#e74c3c" }}>
                                        <FontAwesomeIcon icon={["fad", "times"]} />
                                        <span className="button-text-with-icon">{t("Skip")}</span>
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} className={classes.tabPanel}>
                            <ArticlesManager ref={articlesManager} dropId={parseInt(dropId)} 
                                catalogState={{ 
                                    redirectionUrl: `/routes/timeline/skip/${dropId}`,
                                    redirectionTab: value 
                                }} />
                        </TabPanel>
                        <Fade in={showValidationSummary}>
                            <Box textAlign="center">
                                <Chip label={t("ValidationErrorsOccured")} style={{ borderColor: "#e74c3c", backgroundColor: "#e74c3c", color: "#FFF", marginTop: 15, marginBottom: 15 }} />
                            </Box>
                        </Fade>
                    </>
            }
        </motion.div>
    );
}

export default Skip;