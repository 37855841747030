import React from "react";

const lookUpDescriptionTranslation = (article, language) => {
    var translation;

    if (article != null && article.descriptionTranslations != null && article.descriptionTranslations.length > 0) {
        translation = article.descriptionTranslations.find(t => t.language?.abbreviation.toLowerCase() === language.toLowerCase());
        
        // Fallback for a language which doesn't exists inside the labels.
        if (translation == null)
            translation = article.descriptionTranslations[0];
    }

    return translation?.translatedTerm;
}

const initialFilters = {
    name: '',
    description: '',
    stockArticles: '',
    brandCode: '',
    brandDescription: '',
    supplierArticleCode: ''
};

const determineFilterableFields = (state) => {
    let initialArticles = state?.articles ?? [];

    return {
        ...state,
        filterableNames: [
            ...new Set(
                initialArticles.filter(article => !isNullOrEmpty(article.name))
                    .map(article => article.name)
                    .sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
            )
        ],
        filterableBrandCodes: [
            ...new Set(
                initialArticles.filter(article => !isNullOrEmpty(article.brandCode))
                    .map(article => article.brandCode)
                    .sort((a, b) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}))
            )
        ],
        filterableBrandDescriptions: [
            ...new Set(
                initialArticles.filter(article => !isNullOrEmpty(article.brandDescription))
                    .map(article => article.brandDescription)
                    .sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
            )
        ],
        filterableDescriptions: [
            ...new Set(
                initialArticles.map(article => lookUpDescriptionTranslation(article, state?.language))
                    .filter(translatedDescription => !isNullOrEmpty(translatedDescription))
                    .sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
            )
        ],
        filterableSupplierArticleCodes: [
            ...new Set(
                initialArticles.filter(article => !isNullOrEmpty(article.supplierArticleCode))
                    .map(article => article.supplierArticleCode)
                    .sort((a, b) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}))
            )
        ]
    };
};

const articleReducerInit = (defaultValue = { 
    language: '', 
    articles: [],
    initialFilters: initialFilters
}) => {
    let initialArticles = defaultValue?.articles ?? [];

    let initialState = {
        language: defaultValue?.language,
        articles: initialArticles,
        visibleArticles: initialArticles,
        initialFilters: defaultValue?.initialFilters ?? initialFilters,
        filters: defaultValue?.initialFilters ?? initialFilters,
    };

    return determineFilterableFields(initialState);
};

const ArticleReducer = (state, action) => {
    switch(action.type) {
        case 'FILTER':
            let filters = state.filters;
            let filteredArticles = state.articles
                .filter(article => {
                    let finalResult = true;

                    let filterNameResult = !isNullOrEmpty(filters.name) && article.name?.toLowerCase().includes(filters.name.toLowerCase());
                    if (!isNullOrEmpty(filters.name))
                        finalResult = finalResult && filterNameResult;
                    
                    let translatedDescription = lookUpDescriptionTranslation(article, state.language);
                    let filterDescriptionResult = !isNullOrEmpty(filters.description) && translatedDescription?.toLowerCase().includes(filters.description?.toLowerCase());
                    if (!isNullOrEmpty(filters.description))
                        finalResult = finalResult && filterDescriptionResult;

                    if (!isNullOrEmpty(filters.stockArticles)) {
                        let filterStockArticlesResult = (filters.stockArticles === 'no' && !article.isStockItem) || (filters.stockArticles === 'yes' && article.isStockItem);
                        finalResult = finalResult && filterStockArticlesResult;
                    }

                    let brandCodeResult = !isNullOrEmpty(filters.brandCode) && article.brandCode?.toLowerCase() === filters.brandCode.toLowerCase();
                    if (!isNullOrEmpty(filters.brandCode))
                        finalResult = finalResult && brandCodeResult;
                    
                    let brandDescriptionResult = !isNullOrEmpty(filters.brandDescription) && article.brandDescription?.toLowerCase().includes(filters.brandDescription.toLowerCase());
                    if (!isNullOrEmpty(filters.brandDescription))
                        finalResult = finalResult && brandDescriptionResult;
                    
                    let supplierArticleCodeResult = !isNullOrEmpty(filters.supplierArticleCode) && article.supplierArticleCode?.toLowerCase() === filters.supplierArticleCode.toLowerCase();
                    if (!isNullOrEmpty(filters.supplierArticleCode))
                        finalResult = finalResult && supplierArticleCodeResult;

                    return finalResult;
                });

            return { 
                ...state, 
                visibleArticles: filteredArticles 
            };
        case 'FILTER_CHANGE':
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.filterField]: action.filterValue
                }
            };
        case 'FILTER_RESET':
            return { 
                ...state,
                filters: state.initialFilters,
                visibleArticles: state.articles
            };
        case 'FILTER_RESET_BY_LANGUAGE':
            let newState = { 
                ...state,
                language: action.language
            };

            return determineFilterableFields(newState);
        default:
            break;
    }
};

const isNullOrEmpty = (text) => {
    return text == null || text.match(/^\s*$/) !== null;
}

const ArticleCatalogFilterContext = React.createContext();

export {ArticleCatalogFilterContext, ArticleReducer, articleReducerInit};